<div class="scp_theme mat-typography">
  <h2 mat-dialog-title>{{ (isNew ? 'scp.reports.schedule.upsert.dialog_add_title' : 'scp.reports.schedule.upsert.dialog_edit_title') | translate }}</h2>

  <form novalidate autocomplete="off" validateFormAndScrollToFirstError [formGroup]="reportScheduleFormGroup" (ngSubmit)="upsertReportSchedule()">
    <mat-dialog-content>
      <div class="fx_columnwarp fx_gap_15">
        <mat-form-field>
          <mat-label>{{ 'scp.reports.schedule.upsert.fields.schedule_name' | translate }}</mat-label>
          <input matInput [formControl]="newScheduleNameFormCtrl" />
          <mat-error *ngIf="newScheduleNameFormCtrl.hasError('required')">{{ 'scp.common.validations.required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'scp.reports.schedule.upsert.fields.report_name' | translate }}</mat-label>
          <mat-select [formControl]="reportNameFormCtrl">
            <mat-option *ngFor="let report of reportList" [value]="report.guid">
              {{
                hasTranslation('scp.report.' + report.name.replaceAll(' ', '').toLowerCase())
                  ? ('scp.report.' + report.name.replaceAll(' ', '').toLowerCase() | translate)
                  : report.name
              }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reportNameFormCtrl.hasError('required')">{{ 'scp.common.validations.required' | translate }}</mat-error>
        </mat-form-field>

        <h3>{{ 'scp.reports.schedule.upsert.fields.export_options' | translate }}</h3>
        <mat-radio-group [formControl]="exportTypeFormCtrl" class="fx_gap_15">
          <label>{{ 'scp.reports.schedule.upsert.fields.export_format' | translate }}</label>
          <mat-radio-button value="pdf">{{ 'scp.reports.schedule.upsert.fields.export_pdf_format' | translate }}</mat-radio-button>
          <mat-radio-button value="xlsx">{{ 'scp.reports.schedule.upsert.fields.export_excel_format' | translate }}</mat-radio-button>
        </mat-radio-group>

        <h3>{{ 'scp.reports.schedule.upsert.fields.schedule_options' | translate }}</h3>
        <mat-radio-group [formControl]="occurrenceFormCtrl" class="fx_gap_15">
          <label>{{ 'scp.reports.schedule.upsert.fields.schedule_occurrence' | translate }}</label>
          <mat-radio-button value="2">{{ 'scp.reports.schedule.upsert.fields.schedule_recurrence' | translate }}</mat-radio-button>
        </mat-radio-group>

        <mat-radio-group [formControl]="frequencyFormCtrl" class="fx_gap_15">
          <label>{{ 'scp.reports.schedule.upsert.fields.schedule_frequency' | translate }}</label>
          <mat-radio-button value="DAILY">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_daily' | translate }}</mat-radio-button>
          <mat-radio-button value="WEEKLY">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_weekly' | translate }}</mat-radio-button>
          <mat-radio-button value="MONTHLY">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_monthly' | translate }}</mat-radio-button>
        </mat-radio-group>

        <mat-label *ngIf="frequencyFormCtrl.value == 'WEEKLY'">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_week_days' | translate }}</mat-label>
        <mat-selection-list *ngIf="frequencyFormCtrl.value == 'WEEKLY'" [formControl]="daysOfWeekFormCtrl" [compareWith]="compare">
          <mat-list-option value="MO">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_monday' | translate }}</mat-list-option>
          <mat-list-option value="TU">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_tuesday' | translate }}</mat-list-option>
          <mat-list-option value="WE">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_wednesday' | translate }}</mat-list-option>
          <mat-list-option value="TH">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_thursday' | translate }}</mat-list-option>
          <mat-list-option value="FR">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_friday' | translate }}</mat-list-option>
          <mat-list-option value="SA">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_saturday' | translate }}</mat-list-option>
          <mat-list-option value="SU">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_sunday' | translate }}</mat-list-option>
        </mat-selection-list>

        <mat-form-field *ngIf="frequencyFormCtrl.value == 'MONTHLY'">
          <mat-label>{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_months_days' | translate }}</mat-label>
          <mat-select [formControl]="daysOfMonthFormCtrl">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="7">7</mat-option>
            <mat-option value="8">8</mat-option>
            <mat-option value="9">9</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="11">11</mat-option>
            <mat-option value="12">12</mat-option>
            <mat-option value="13">13</mat-option>
            <mat-option value="14">14</mat-option>
            <mat-option value="15">15</mat-option>
            <mat-option value="16">16</mat-option>
            <mat-option value="17">17</mat-option>
            <mat-option value="18">18</mat-option>
            <mat-option value="19">19</mat-option>
            <mat-option value="20">20</mat-option>
            <mat-option value="21">21</mat-option>
            <mat-option value="22">22</mat-option>
            <mat-option value="23">23</mat-option>
            <mat-option value="24">24</mat-option>
            <mat-option value="25">25</mat-option>
            <mat-option value="26">26</mat-option>
            <mat-option value="27">27</mat-option>
            <mat-option value="28">28</mat-option>
            <mat-option value="29">29</mat-option>
            <mat-option value="30">30</mat-option>
            <mat-option value="31">31</mat-option>
            <mat-option value="28,29,30,31">{{ 'scp.reports.schedule.upsert.fields.schedule_frequency_last_day_of_month' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'scp.reports.schedule.upsert.fields.start_date_range' | translate }}</mat-label>
          <input matInput [ngxMatDatetimePicker]="picker1" [formControl]="scheduleStartDateTimeFormCtrl" [min]="minDate" />
          <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker1
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
          >
          </ngx-mat-datetime-picker>

          <mat-error *ngIf="scheduleStartDateTimeFormCtrl.hasError('required')">{{ 'scp.common.validations.required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'scp.reports.schedule.upsert.fields.end_date_range' | translate }}</mat-label>
          <input matInput [ngxMatDatetimePicker]="picker2" [formControl]="scheduleEndDateTimeFormCtrl" [min]="minDate" />
          <mat-datepicker-toggle matSuffix [for]="picker2"> </mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker2
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
          >
          </ngx-mat-datetime-picker>

          <mat-error *ngIf="scheduleEndDateTimeFormCtrl.hasError('required')">{{ 'scp.common.validations.required' | translate }}</mat-error>
        </mat-form-field>

        <h3>{{ 'scp.reports.schedule.upsert.fields.recipients' | translate }}</h3>
        <mat-form-field>
          <mat-label>{{ 'scp.reports.schedule.upsert.fields.recipient_list' | translate }}</mat-label>
          <mat-chip-grid #chipList [formControl]="recipientsFormCtrl">
            <mat-chip-row *ngFor="let email of recipientsFormCtrl.value" [selectable]="true" [removable]="true" (removed)="remove(email)">
              {{ email }}
              <mat-icon matChipRemove>clear</mat-icon>
            </mat-chip-row>
            <input
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-grid>
          <mat-error *ngIf="recipientsFormCtrl.hasError('required')">{{ 'scp.common.validations.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>{{ 'scp.common.cancel_button_text' | translate }}</button>
      <button type="submit" mat-flat-button color="primary" [disabled]="isSubmitting">
        {{ (isNew ? 'scp.common.add_button_text' : 'scp.common.save_button_text') | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
