import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from '../../../shared/components/base.component';
import { LogHandlerService } from '../../../shared/services/ssp/log-handler.service';

@Component({
  selector: 'app-device-mfp-search',
  templateUrl: './device-mfp-search.component.html',
})
export class DeviceMFPSearchComponent extends BaseComponent {
  isSubmitting = false;
  showSuccessMessage = false;

  mfpSearchFormGroup: FormGroup;
  rangeFormCtrl: FormControl;

  constructor(private logHandlerService: LogHandlerService) {
    super();

    this.rangeFormCtrl = new FormControl(null, [Validators.required, Validators.pattern(this.validationRegExp.ipAddress)]);

    this.mfpSearchFormGroup = new FormGroup({
      range: this.rangeFormCtrl,
    });
  }

  upsertDevice() {
    if (this.mfpSearchFormGroup.valid) {
      this.isSubmitting = true;

      this.appService
        .addMFPPrinterByIPRange(this.rangeFormCtrl.value)
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.showSuccessMessage = true;
          },
          () => {
            this.logHandlerService.addSystemLogCustom(
              'MFP device search data failed to save (ST00011)',
              'Devices',
              'save MFP device search data',
              'SCP-Portal-ST00011'
            );
            this.appService.showError(this.translate.instant('scp.devices.mfp_search.save_error_message'));

            this.isSubmitting = false;
          }
        );
    }
  }
}
