<form novalidate autocomplete="off" class="scp_theme mat-typography">
  <h2 mat-dialog-title>{{ 'scp.users.upsert.dialog_title' | translate }}</h2>

  <mat-dialog-content>
    <div class="fx_columnwarp">
      <mat-radio-group [formControl]="importTypeFormCtrl" class="fx_columnwarp">
        <div>
          <div class="fx_row">
            <span class="fxFlex_60">
              <mat-radio-button value="idp" (click)="onSelectImportIDP()">
                <mat-label *ngIf="this.isGoogle; else importOffice365">{{
                  'scp.users.upsert.import_user_import_process_from_provider' | translate : '{providerName: "Google Workspace"}'
                }}</mat-label>
                <ng-template #importOffice365>{{
                  'scp.users.upsert.import_user_import_process_from_provider' | translate : '{providerName: "Microsoft 365"}'
                }}</ng-template>
              </mat-radio-button>
            </span>
            <span class="text_right fx_row" id="searchGroup">
              <mat-checkbox [formControl]="searchByGroupsFormCtrl" [disabled]="isIDPImportDisable">
                {{ 'scp.users.upsert.search_by_groups' | translate }}
              </mat-checkbox>
            </span>
          </div>

          <div class="fx_columnwarp" [ngClass]="{ isdisabeled: isIDPImportDisable === true }">
            <mat-radio-group [formControl]="searchByTypeFormCtrl" class="fx_gap_15" [disabled]="isIDPImportDisable">
              <label class="mr-3zsdadasdas">{{ 'scp.users.upsert.search_by' | translate }}</label>
              <mat-radio-button value="byUserName" *ngIf="!searchByGroupsFormCtrl.value">{{
                'scp.users.upsert.search_by_user_name' | translate
              }}</mat-radio-button>
              <mat-radio-button value="byGroupName" *ngIf="!!searchByGroupsFormCtrl.value">
                {{ 'scp.users.upsert.search_by_group_name' | translate }}
              </mat-radio-button>
              <mat-radio-button value="byEmail">{{ 'scp.users.upsert.search_by_email' | translate }}</mat-radio-button>
            </mat-radio-group>

            <mat-hint [ngSwitch]="searchByGroupsFormCtrl.value" class="my-3">
              <ng-container *ngSwitchCase="true" [disabled]="isIDPImportDisable">{{ 'scp.users.upsert.search_by_groups_hint' | translate }}</ng-container>
              <ng-container *ngSwitchDefault [disabled]="isIDPImportDisable">{{ 'scp.users.upsert.search_by_users_hint' | translate }}</ng-container>
            </mat-hint>

            <mat-form-field class="mb-3">
              <mat-label [ngSwitch]="searchByTypeFormCtrl.value">
                <ng-container *ngSwitchCase="'byUserName'"> {{ 'scp.users.upsert.search_by_user_name' | translate }} </ng-container>
                <ng-container *ngSwitchCase="'byGroupName'"> {{ 'scp.users.upsert.search_by_group_name' | translate }} </ng-container>
                <ng-container *ngSwitchCase="'byEmail'"> {{ 'scp.users.upsert.search_by_email' | translate }} </ng-container>
              </mat-label>
              <input
                id="idpSearch"
                required
                matInput
                [formControl]="searchTermFormCtrl"
                [matAutocomplete]="msGraph"
                (keyup)="searchTerm$.next($event.target.value)"
                [disabled]="isIDPImportDisable"
                [readonly]="isIDPImportDisable"
              />
              <mat-error *ngIf="searchTermFormCtrl.hasError('required') && isIDPImportDisable !== true">{{
                'scp.common.validations.required' | translate
              }}</mat-error>
            </mat-form-field>

            <mat-autocomplete #msGraph="matAutocomplete" (optionSelected)="selectItem($event.option.value)" class="scp_theme">
              <mat-option *ngFor="let item of searchResults" [value]="item">
                <ng-container *ngIf="!!this.searchByGroupsFormCtrl.value; else userTemplate">
                  {{ item.groupname }} ({{ item.groupmembercount }}) | {{ item.groupemail }}
                </ng-container>

                <ng-template #userTemplate> {{ item.displayname }} | {{ item.username }} </ng-template>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>

        <div>
          <mat-radio-button class="importMultipleUserTitle" value="csv" (click)="onSelectImportCSV()">
            <mat-label>{{ 'scp.users.upsert.import_user_multiple_user' | translate }}</mat-label>
          </mat-radio-button>
          <div class="container custom_import" [ngClass]="{ isdisabeled: isCSVImportDisable === true }">
            <ng-content disabled="isIDPImportDisable" select=".importMultipleUserTitle"></ng-content>
            <div class="mat-process" mat-dialog-title>{{ 'scp.users.upsert.import_user_process' | translate }}</div>
            <ol disabled="isIDPImportDisable">
              <li *ngIf="this.isGoogle">{{ 'scp.users.upsert.import_user_import_process' | translate : '{providerName: "Google Workspace"}' }}</li>
              <li *ngIf="!this.isGoogle">{{ 'scp.users.upsert.import_user_import_process' | translate : '{providerName: "Microsoft 365"}' }}</li>
              <li>{{ 'scp.users.upsert.import_user_import_process_users_edit' | translate }}</li>
              <li>{{ 'scp.users.upsert.import_user_import_process_return' | translate }}</li>
            </ol>
            <form class="fx_colum" [formGroup]="importUserFormGroup">
              <div mat-dialog-content>
                <div class="choose_file">
                  <input
                    #fileUpload
                    type="file"
                    [disabled]="isCSVImportDisable"
                    (click)="onClick($event)"
                    (change)="onFilesAdded($event)"
                    class="file_browse"
                    accept=".csv"
                    requireFile
                  />
                  <input type="hidden" name="fileHidden" formControlName="fileInput" />
                  <mat-error>
                    <div *ngFor="let errorMessage of validationMessage">{{ errorMessage }}</div>
                  </mat-error>
                </div>
              </div>
            </form>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>{{ 'scp.common.cancel_button_text' | translate }}</button>
    <button type="button" mat-flat-button color="primary" [disabled]="isDisabled()" (click)="insertUser()">
      {{ 'scp.common.save_button_text' | translate }}
    </button>
  </mat-dialog-actions>
</form>
