import { Component, OnInit, ViewChild } from '@angular/core';
import { DateFormatService } from '../../shared/services/jadatetime-format.service';
import dayjs from 'dayjs';
import { DatePipe } from '@angular/common';
import { ExportDialogComponent } from '../../shared/dialogs/export.dialog/export.dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogHandlerService } from '../../shared/services/ssp/log-handler.service';
import { Helper } from '../../shared/services/ssp/helper';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SspGenericService } from '../../shared/services/ssp/ssp-generic.service';
import { LocalAppDataService } from '../../shared/services/local-app-data.service';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import camelCase from 'camelcase';
import { TokenStorageService } from '../../shared/services/ssp/token-storage.service';
import { ManageDeviceService } from '../../shared/services/ssp/manage-device.service';
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'lib-system-log',
    templateUrl: './system-log.component.html',
    styleUrls: ['./system-log.component.scss']
})
export class SystemLogComponent implements OnInit {
    public portalType;
    // viewSystem: ISystemLog;
    protected exportDialogCurrent: any;
    private searchSubscription: any;
    error: any = { isError: false, errorMessage: '' };
    dateQuery = '';
    hideContent = false;
    availableReports = null;
    mainExportUrl: string;
    presentDate: Date;
    tenantDate: Date;
    startDateMinLimit: Date;
    minDate: Date;
    maxDate: Date;
    dataSource: any;
    filterForm: UntypedFormGroup;
    isGo = true;
    isCloudPrint = true;
    isMeeting = true;
    isAdminPortal = true;
    application: string;
    applicationList: any;
    disableRefresh = false;
    service: any;
    displayedColumns: string[] = ['log_generated_date', 'user_name', 'application', 'log_category', 'log_type'];
    ESConditionValues: string[];
    totalCount = 0;
    logData: any[];
    log_start_date: string;
    log_end_date: string;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatSortHeader, { static: true }) matSortHeader: MatSortHeader;
    userOrAgentList: any[];
    userSelected = 0;
    loadingStatus = true;
    public browserLang: string;
    public isRmpExists: boolean;
    public isMarvelExists: boolean;
    public accessToken: any;

    views = [
        { value: 'all', viewValue: this.translate.instant('All') },
    ];
    viewScp: boolean;

    constructor(
        private token: TokenStorageService,
        private datePipe: DatePipe,
        private sspApiService: SspGenericService,
        private exportDialog: MatDialog,
        private http: HttpClient,
        private dateAdapter: DateAdapter<Date>,
        private translate: TranslateService,
        private logHandlerService: LogHandlerService,
        private jadateTimeService: DateFormatService,
        private appDataService: LocalAppDataService,
        private manageDeviceService: ManageDeviceService
        ) 
        {
            this.initDates();
            this.browserLang = this.appDataService.browserLang();
            this.dateAdapter.setLocale(this.browserLang);
        }

    formatDate(dt) {
        return this.jadateTimeService.formatDateTimeLocale(dt)
    }

    ngOnInit(): void {
        this.appDataService.usingAuthInfo().subscribe((token) => {
            if (token) {
                this.accessToken = token;
            }
        });

        this.mainExportUrl = this.sspApiService.exportlogUrl;
        this.filterForm = new UntypedFormGroup({
            View: new UntypedFormControl(0),
        });

        this.paginator.pageSize = this.token.getLogPageSize('systemLog') !== null ? this.token.getLogPageSize('systemLog') : 25;
        this.sort.active = 'log_generated_date';
        this.sort.direction = 'desc';

        //Based on SISE request I am removing Synappx Go Logs
        this.isMarvelExists = false // this.appDataService.ViewGo;
        this.isRmpExists = false // this.appDataService.ViewMeeting;
        this.viewScp = this.appDataService.ViewScp;
        this.service = this.serviceSelection(this.isRmpExists, this.isMarvelExists, this.viewScp);
        this.searchLogData();
        this.getUniqueUsersAndAgentsList();
    }
    getUniqueUsersAndAgentsList() {
        const schemaName = 'synappx_system_v1';
        const tenantId = this.appDataService.ConnectionName;
        this.sspApiService.getUniqueUsersAndAgentsList(schemaName, tenantId, this.log_start_date, this.log_end_date, this.ESConditionValues).subscribe(data => {
            const usersList = data.aggregations.users_list.buckets.map((function (obj) { return obj.key }));
            this.userOrAgentList = usersList;
        });
    }

    /**
     * Initialize the dates paramters
     */
    initDates() {
        const daysToReduce = 29; // Based on task SHPLT-1591 ninety days including today to be used for min date while selecting the dates
        this.tenantDate = this.appDataService.TenantInfoAtLogin.dateCreated;
        this.presentDate = new Date(Date.now());
        this.startDateMinLimit = new Date(this.presentDate.getFullYear(), this.presentDate.getMonth(), this.presentDate.getDate() - daysToReduce);
        this.minDate = (this.tenantDate && new Date(this.tenantDate) > this.startDateMinLimit) ? new Date(this.tenantDate) : this.startDateMinLimit;
        this.maxDate = this.presentDate;
        this.log_start_date = this.dateString(this.startDateMinLimit);
        this.log_end_date = this.dateString(this.presentDate);

    }

    getExport(startDate: string, endDate: string) {
        // Check if startDate or endDate is empty
        if (startDate === '' || endDate === '') {
            this.error = { isError: true, errorMessage: this.translate.instant('StartAndEndDateRequired') };
            return false;
        }
        startDate = this.jadateTimeService.parseDateLocale(startDate);
        endDate = this.jadateTimeService.parseDateLocale(endDate);
        if (endDate < startDate) {
            this.error = { isError: true, errorMessage: this.translate.instant('EndDateGreater') };
            return false;
        }
        this.error = { isError: false, errorMessage: '' };
        this.getExportForDate(new Date(startDate), new Date(endDate));
    }


     /**
     * Show the export data with the given endDate
     * @param endDate This is the end date for exporting raw data
     */
    getExportForDate(startDate: Date, endDate: Date) {
        const selectedDate = new Date(endDate);
        selectedDate.setDate(selectedDate.getDate() + 1);
        let timeZone = dayjs().format('Z');
        const toDate = encodeURIComponent(dayjs(selectedDate).format('YYYY-MM-DDTHH:mm:ss') + timeZone);
        const fromDate = encodeURIComponent(dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss') + timeZone);
        timeZone = encodeURIComponent(timeZone);
        const langCode = (this.browserLang || 'en').toLowerCase().split('-').join('_');
        this.dateQuery = `from=${fromDate}&to=${toDate}&timezone=${timeZone}&lang=${langCode}`;
        this.exportDialogCurrent = this.exportDialog.open(ExportDialogComponent, {
            minHeight: '30%',
        });
        const datePrefix = this.datePipe.transform(new Date(), 'MM-dd-yy');
        this.availableReports = {
            synappx_audit_system: `${datePrefix}_Synappx_System_Log.zip`,
        };
        const schemaIds = Object.keys(this.availableReports);
        const urlList = schemaIds.map((schema) => `${this.mainExportUrl}/${schema}?${this.dateQuery}`);
        if (this.exportDialogCurrent.componentInstance) {
            this.exportDialogCurrent.componentInstance.setReportCount(schemaIds.length);
        }
        this.downloadInMemory(urlList);
    }

    /**
     * Download a list of URLs in sequence
     * @param urlList The array of URLs
     *    Only after download of one URL is complete, download of another URL will start.
     *    This sequencing is done by recursive function calls as download operation is asynchronous
     */
    downloadInMemory(urlList) {
        // Take latest url
        const url = urlList.pop();
        let fileName = url.split('?')[0].split('/').pop();
        if (fileName in this.availableReports) {
            fileName = this.availableReports[fileName];
        }
        this.getBlob(url, fileName);
    }

    getBlob(url, fileName) {

        const httpOptions: {
            headers: HttpHeaders,
            observe: 'response',
            responseType: 'blob',
        } = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.accessToken,
                'accept': '*/*',
            }),
            observe: 'response',
            responseType: 'blob',
        };
        return this.http.get(url, httpOptions).subscribe((res) => {
            if (res.body) {
                Helper.downloadFile(fileName, res.body);
                this.logHandlerService.addAdminLog('System Log', 'System Log exported', 'MAA00033', 'System Log exported');
            } else {
                if (this.exportDialogCurrent.componentInstance) {
                    const errorMessage = this.translate.instant('ExportErrorNoData', { fileName: fileName });
                    this.exportDialogCurrent.componentInstance.AddError(errorMessage);
                }
            }
        }, (err) => {
            if (this.exportDialogCurrent.componentInstance) {
                this.logHandlerService.addSystemLogCustom('Failed to export System Log', 'System Log', 'export System Log', 'MST20022');
                const errorMessage = this.translate.instant('ExportErrorNoData', { fileName: fileName });
                this.exportDialogCurrent.componentInstance.AddError(errorMessage);
                this.exportDialogCurrent.componentInstance.downloadComplete(true);
            }
        },
            () => {
                if (this.exportDialogCurrent.componentInstance) {
                    this.exportDialogCurrent.componentInstance.downloadComplete(true);
                }
            });
    }

    /**
     * Parse the fileName from content-disposition header in the response
     * @param disposition The value of content-disposition header present in response
     * @param defaultValue The default value of fileName if fileName is not present in content-disposition header
     */
    parseFileName(disposition, defaultValue) {
        let fileName = defaultValue;
        if (disposition && disposition.indexOf('attachment') >= 0 && disposition.indexOf('filename') >= 0) {
            const parts = disposition.split(';');
            const name = parts.find((part) => part.indexOf('filename') >= 0);
            if (name) {
                const pair = parts[1].split('=');
                fileName = pair[1];
                fileName = fileName.startsWith('"') ? fileName.slice(1) : fileName;
                fileName = fileName.endsWith('"') ? fileName.slice(0, -1) : fileName;
            }
        }
        return fileName;
    }

    /**
     * Get the string representation of date (in ISO 8601 format)
     */
    dateString(date: Date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    refreshPage() {
        this.paginator.pageIndex = 0;
        const sortState: Sort = { active: 'log_generated_date', direction: 'desc' };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.getUniqueUsersAndAgentsList();
        this.searchLogData();
        this.sort.sortChange.emit();
        // sets the animation transition view state for the arrow's position and opacity.
        this.matSortHeader._setAnimationTransitionState({ toState: 'active' });
    }
    serviceSelected(service) {
        if(this.isRmpExists || this.isMarvelExists || this.viewScp) {
            this.filterForm.get('View').enable();
            this.disableRefresh = false;
            if (service === 'Synappx Go') {
                this.isGo = !this.isGo;
            } else if (service === 'Synappx Cloud Print'){
               this.isCloudPrint =!this.isCloudPrint;
            } else {
                this.isAdminPortal = !this.isAdminPortal;
            }
            const filteredServices = [];
            if (this.isGo && this.isMarvelExists) {
                filteredServices.push('Synappx Go', 'Synappx Go Agent', 'Synappx Go Mobile');
            }
            if (this.isGo && this.isRmpExists) {
                filteredServices.push('Synappx Meeting', 'Synappx Go Windows');
            }
            if (this.isAdminPortal) {
                filteredServices.push('Admin Portal');
            }
            if (this.isCloudPrint) {
                filteredServices.push('Cloud Print Admin Portal', 'Cloud Print MFP', 'Cloud Print Client');
            }
            if (!this.isCloudPrint && !this.isAdminPortal) {
                this.filterForm.get('View').setValue(this.userSelected);
                this.filterForm.get('View').disable();
            }
            this.ESConditionValues = filteredServices;
            this.paginator.pageIndex = 0;
            this.searchLogData();
            }
    }

    selectedUser(event) {
        this.userSelected = event.value;
        this.paginator.pageIndex = 0;
        this.searchLogData();
    }

    searchLogData() {
        const schemaName = 'synappx_system_v1';
        const tenantId = this.appDataService.ConnectionName;
        const ESConditionField = 'application';
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        this.searchSubscription = this.sspApiService.searchLogData(schemaName, tenantId, this.log_start_date, this.log_end_date, this.sort.active, this.sort.direction, this.paginator.pageSize, this.paginator.pageIndex, ESConditionField, this.ESConditionValues, this.userSelected).subscribe((data) => {
            if (data.hits) {
                this.totalCount = data.hits.total.value;
            }
            if (data.hits.hits) {
                this.dataSource = data.hits.hits.map(item => {
                    // convert log_category value to match with l10n file id
                    let camelCaseId = camelCase(item._source.log_category.toLowerCase());
                    let log_category = this.translate.instant('value.' + camelCaseId);
                    //If value.XXXXXX cannot be gotten from l10n file show item._source.log_category value as it is
                    item._source.log_category = (log_category.includes('value.')) ? item._source.log_category : log_category;
                    //If _isMfpRemoveEnabled is true, log message is displayed with hyperlink else only message.
                    // Only for log_type_id = 'Go-S122' hyperlink is displayed and allowed to remove MFP device.
                    item['_isMfpRemoveEnabled'] = (item._source.log_type_id && (item._source.log_type_id.toUpperCase() === 'GO-S122')) ? true : false;
                    //If log_type_id is there translate it otherwise show log_type as it is
                    let log_type_id = (item._source.log_type_id) ? this.translate.instant('message.' + item._source.log_type_id) : item._source.log_type;
                    //If message.XXXXXX cannot be gotten from l10n file show item._source.log_type_id value as it is
                    item._source.log_type_id = (log_type_id.includes('message.')) ? item._source.log_type_id : log_type_id;
                    item._source.user_name = (item._source.user_name === 'N/A') ? item._source.device_name : item._source.user_name;
                    return item;
                });
            }
            this.loadingStatus = false;
        });
    }

    onPagination(pageEvent: PageEvent) {
        this.token.setLogPageSize(pageEvent.pageSize, 'systemLog');
        this.searchLogData();
    }

    onSorting() {
        this.searchLogData();
    }

    ///This function allows us to remove already registered MFP device on confirmation.
    async removeMFPDevice(detail: string, deviceId: string, agentId: string) {
        try {
            const agentName = detail.split(',')[0].split(':')[1];
            const ipAddress = detail.split(',')[1].split(':')[1];
            const params = [];
            params.push({ id: deviceId, agentId: agentId });
            const placeHolderVariables = {
                ipAddress: ipAddress,
                agentName: agentName
            }
            const data = {
                mainMessage: 'ConfirmRemoveMFPDevice',
                placeHolderVariables: placeHolderVariables,
                params: params,
                failureNotificationMsg: 'DeviceAlreadyDeleted',
                deviceType: 'mfpagent'
            }
            await this.manageDeviceService.removeDevices(data);
        } catch (error) {
            console.log(error);
        }
    }

    serviceSelection(isRmpExists, isMarvelExists, viewScp) {
        this.ESConditionValues = ['Admin Portal'];
        if (viewScp) {
            this.ESConditionValues.push(...['Cloud Print Admin Portal', 'Cloud Print MFP', 'Cloud Print Client']);
        }
        if (isRmpExists) {
            this.ESConditionValues.push(...['Synappx Meeting', 'Synappx Go Windows']);
        }
        if (isMarvelExists) {
            this.ESConditionValues.push(...['Syanppx Go', 'Synappx Go Agent', 'Synappx Go Mobile']);
        }
        return this.ESConditionValues;
    }
}
