<form
  novalidate
  autocomplete="off"
  validateFormAndScrollToFirstError
  [formGroup]="departmentFormGroup"
  class="scp_theme mat-typography"
  (ngSubmit)="upsertDepartment()"
>
  <h2 mat-dialog-title>{{ (isAdd ? 'scp.departments.upsert.dialog_add_title' : 'scp.departments.upsert.dialog_edit_title') | translate }}</h2>

  <mat-dialog-content>
    <div class="fx_columnwarp fx_gap_0">
      <mat-form-field>
        <input matInput placeholder="{{ 'scp.departments.upsert.department_name_field_text' | translate }}" [formControl]="nameFormCtrl" />
        <mat-error *ngIf="nameFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>{{ 'scp.common.cancel_button_text' | translate }}</button>
    <button type="submit" mat-flat-button color="primary" [disabled]="isSubmitting">{{ 'scp.common.save_button_text' | translate }}</button>
  </mat-dialog-actions>
</form>
