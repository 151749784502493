import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { RRule } from 'rrule';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../../../shared/components/base.component';
import { LogHandlerService } from '../../../shared/services/ssp/log-handler.service';
import { ReportsScheduleUpsertComponent } from '../reports-schedule-upsert/reports-schedule-upsert.component';
import { SubDialogInfo } from '../../../scp-shared/interfaces/dialog';
import { DialogService } from '../../../scp-shared/services/dialog.service';

const PAGE_NAME = 'REPORTS_SCHEDULE';

@Component({
  selector: 'app-reports-schedule',
  templateUrl: './reports-schedule.component.html',
})
export class ReportsScheduleComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['status', 'name', 'reportName', 'schedule', 'nextRun', 'until', 'exportFormat', 'createdBy', 'actions'];

  resultsLength = 0;

  constructor(private dialogService: DialogService, private logHandlerService: LogHandlerService) {
    super();
  }

  ngOnInit() {
    this.getReportSchedulesData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // Reset the page number
    //this.sort.sortChange.pipe(this.takeUntilDestroyed()).subscribe(() => (this.paginator.pageIndex = 0));
    this.sort.sortChange.emit();

    Promise.resolve().then(() => {
      this.paginator.pageSize = this.appService.getPageSizeInfo(PAGE_NAME);
    });

    this.paginator.page.pipe(this.takeUntilDestroyed()).subscribe((evt) => {
      this.appService.savePageSizeInfo(PAGE_NAME, evt.pageSize);
    });
  }

  applyFilter(filterString = '') {
    this.dataSource.filter = filterString.trim().toLowerCase();
  }

  hasTranslation(key) {
    const translation = this.translate.instant(key);
    return translation !== key && translation !== '';
  }

  // RRule Localisation
  language = {
    dayNames: moment.weekdays(),
    monthNames: moment.months(),
    tokens: {},
  };

  // RRule Localisation
  getText = (id) => {
    return this.translate.instant('scp.common.rrule.' + id);
  };

  // RRule Localisation
  dateFormat = (year, month, day) => moment().date(day).year(year).month(month).format('LL');

  showUpsertReportScheduleDialog(rowData?) {
    this.dialog
      .open(ReportsScheduleUpsertComponent, {
        data: rowData,
        width: '500px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed<boolean>())
      .subscribe((shouldUpdateView) => {
        if (shouldUpdateView) {
          this.getReportSchedulesData();
        }
      });
  }

  getReportSchedulesData() {
    this.appService
      .getReportSchedules()
      .pipe(
        this.takeUntilDestroyed(),
        map((data) => {
          return data.map((item) => {
            return {
              guid: item.guid,
              recurrenceRule: item.recurrenceRule,
              fromDttm: moment.utc(item.fromDttm).utcOffset(moment().utcOffset()),
              toDttm: moment.utc(item.toDttm).utcOffset(moment().utcOffset()),
              reportTemplateGroup: item.reportTemplateGroup,
              recipients: item.recipients,
              status: item.status,
              name: item.name,
              reportName: item.reportTemplateGroup.name,
              schedule: RRule.fromString(item.recurrenceRule).toText(this.getText, this.language, this.dateFormat),
              nextRun:
                item.nextRunDttm != null
                  ? moment.utc(item.nextRunDttm).utcOffset(moment().utcOffset()).locale(navigator.language).format('L LT')
                  : item.nextRunDttm,
              until: item.toDttm != null ? moment.utc(item.toDttm).utcOffset(moment().utcOffset()).locale(navigator.language).format('L LT') : item.toDttm,
              exportFormat: item.renderer,
              createdBy: item.createdUser.displayname,
            };
          });
        })
      )
      .subscribe(
        (data) => {
          this.dataSource.data = data;
        },
        () => {
          this.logHandlerService.addSystemLogCustom('Report schedule failed to save (ST00027)', 'Reports', 'list report schedule ', 'SCP-Portal-ST00027');
          this.appService.showError(this.translate.instant('scp.reports.schedule.upsert.save_error_message'));
        }
      );
  }

  // deleteReportSchedule(data) {
  //   if (this.translate.instant('scp.reports.schedule.confirm_delete_message', { value: data.name })) {
  //     this.appService
  //       .deleteReportSchedule(data.guid)
  //       .pipe(this.takeUntilDestroyed())
  //       .subscribe(
  //         () => {
  //           this.getReportSchedulesData();
  //         },
  //         () => {
  //           this.appService.showError(this.translate.instant('scp.reports.schedule.delete_error_message', { value: data.name }));
  //         }
  //       );
  //   }
  // }

  deleteReportSchedule(data) {
    return new Promise((resolve, reject) => {
      const subDialogInfo: SubDialogInfo = {
        title: this.translate.instant('scp.common.confirm'),
        mainMessage: this.translate.instant('scp.reports.schedule.confirm_delete_message', { value: data.name }),
        cancelText: this.translate.instant('scp.common.cancel_button_text'),
        confirmText: this.translate.instant('scp.common.ok_button_text'),
        infoMode: false,
        placeHolderVariables: this.translate.instant('scp.reports.schedule.confirm_delete_message', { value: data.name }),
      };
      this.dialogService.open(subDialogInfo);
      this.dialogService.confirmed().subscribe((result) => {
        if (result === 1) {
          //Indicates Action Confirmed
          this.appService
            .deleteReportSchedule(data.guid)
            .pipe(this.takeUntilDestroyed())
            .subscribe(
              () => {
                this.getReportSchedulesData();
              },
              () => {
                this.logHandlerService.addSystemLogCustom(
                  'Report schedule failed to delete (ST00028)',
                  'Reports',
                  'delete report schedule',
                  'SCP-Portal-ST00028'
                );
                this.appService.showError(this.translate.instant('scp.reports.schedule.delete_error_message', { value: data.name }));
              }
            );
        } else {
          //Indicates Action Cancelled
          resolve(0);
        }
      });
    });
  }
}
