import { Injectable } from '@angular/core';
import { ViewManagementService } from './view-management.service';
import { IRmmComponentStates } from '../interfaces/componentStates';

@Injectable({
    providedIn: "root",
})
export class RmmValidateUserService {
    public filterRoutes: any;
    rmmService = [
        { value: "rmmDashboard", viewLink: "rmm/dashboard" },
        { value: "rmmGROP", viewLink: "rmm/grop" },
        { value: "rmmCustomDeviceType", viewLink: "rmm/customDeviceType" },
        {
            value: "devices",
            viewLink: "rmm/mfp-printers",
            children: [
                { value: "MFPPrinters", viewLink: "rmm/mfp-printers" },
                { value: "displays", viewLink: "rmm/displays" },
                { value: "CreateSchedule", viewLink: "rmm/create-schedule" },
                { value: "rmmDeviceCloning", viewLink: "rmm/device-cloning" },
                { value: "storageBackup", viewLink: "rmm/storage-backup" },
                { value: "printerDriver", viewLink: "rmm/drivers" },
                { value: "rmmDeviceType", viewLink: "rmm/device-type" },
                { value: "rmmdevicesfirmwareupdate", viewLink: "rmm/devices/firmware-update" },
                { value: "rmmdevicesfirmwareupdateeu", viewLink: "rmm/devices/firmware-update-EU" },
            ],
        },
        {
            value: "Security",
            viewLink: "rmm/security-control",
            children: [
                {
                    value: "rmmSecurityControl",
                    viewLink: "rmm/security-control",
                },
                { value: "rmmCreatePolicy", viewLink: "rmm/create-policy" },
            ],
        },
        {
            value: "analytics",
            viewLink: "rmm/rmm-reports",
            children: [
                { value: "fleetReport", viewLink: "rmm/fleet-report" },
                { value: "usageReport", viewLink: "rmm/usage-report" },
                { value: "securityReport", viewLink: "rmm/security-report" },
            ],
        },
        { value: "rmmTasks", viewLink: "rmm/tasks" },
        {
            value: "Settings",
            viewLink: "rmm/rmm-users",
            children: [
                { value: "AdminUsers", viewLink: "rmm/rmm-users" },
                { value: "SupportedDomains", viewLink: "rmm/multi-domain" },
                { value: "Tenant", viewLink: "/rmm/tenant" },
                { value: "Agent", viewLink: "rmm/agent" },
                { value: "rmmEmailAlert", viewLink: "rmm/email-alert" },
                {
                    value: "CounterVariation",
                    viewLink: "rmm/counter-variation",
                },
                { value: "connectWise", viewLink: "rmm/connect-wise" },
                { value: "rmmCeoJuice", viewLink: "rmm/ceo-juice" },
                { value: "AgentDownloads", viewLink: "rmm/downloads" },
            ],
        },
        {
            value: "System",
            viewLink: "rmm/rmmauditlogs",
            children: [
                { value: "AdminLog", viewLink: "rmm/rmmauditlogs" },
                { value: "OperationLog", viewLink: "rmm/operation-logs" },
                { value: "DeviceLog", viewLink: "rmm/device-logs" },
                { value: 'rmmagentlog', viewLink: 'rmm/rmm-agent-logs' },
                { value: "About", viewLink: "rmm/about" },
            ],
        },
        {
            value: "TechnicalService",
            viewLink: "rmm/service-report",
            children: [
                { value: "ServiceReport", viewLink: "rmm/service-report" },
                { value: "alerts", viewLink: "rmm/alert" },
                { value: "GetData", viewLink: "rmm/getdata" },
                {
                    value: "RemoteMaintenance",
                    viewLink: "rmm/remote-maintenance",
                },
                { value: "FirmwareUpdate", viewLink: "rmm/firmware-update" },
            ],
        },
    ];

    rmmViewList: IRmmComponentStates;

    constructor(private viewManagementService: ViewManagementService) {
        this.rmmViewList = this.viewManagementService.rmmComponentStates;
    }

    // method to validate url is accessible by user
    checkValidateMethod(url) {
        let filteredList = this.filterList();
        let pathExist: boolean = false;
        filteredList.forEach((ele) => {
            if (ele.children && ele.children.length > 0) {
                ele.children.filter((e) => {
                    if (url && url.includes(e.viewLink)) {
                        pathExist = true;
                    }
                });
            } else {
                if (url && url.includes(ele.viewLink)) {
                    pathExist = true;
                }
            }
        });
        return pathExist;
    }

    // method to filter url based on component states
    filterList() {
        let filter = this.rmmService.filter((ele, _i) => {
            if (ele.children && ele.children.length > 0) {
                ele.children = ele.children.filter((e) => {
                    if (
                        this.rmmViewList[e.value] &&
                        this.rmmViewList[e.value].view
                    ) {
                        return true;
                    }
                });
            }
            if (
                this.rmmViewList[ele.value] &&
                this.rmmViewList[ele.value].view
            ) {
                return true;
            }
        });
        this.filterRoutes = filter;
        return filter;
    }
}
