<mat-card class="scp_dashboard_container">
  <div class="fx_space-between-start">
    <mat-card-title>{{ 'scp.common.dashboard' | translate }}</mat-card-title>

    <div class="scp_sustainability fx_startstretch fx_gap_15">
      <div
        *ngIf="savedPaperData?.tenantPaperSavedAgainstLastMonth >= 0"
        class="scp_sustainability_saved_paper"
        [innerHTML]="
          (savedPaperData?.tenantTotalPaperSavedThisMonth
            ? 'scp.dashboard.sustainability.saved_paper_message_more'
            : 'scp.dashboard.sustainability.saved_paper_no_data_message'
          ) | translate : { value: savedPaperData?.tenantTotalPaperSavedThisMonth || 0, value1: savedPaperData?.tenantPaperSavedAgainstLastMonth || 0 }
        "
      ></div>

      <div
        *ngIf="savedPaperData?.tenantPaperSavedAgainstLastMonth < 0"
        class="scp_sustainability_saved_paper"
        [innerHTML]="
          (savedPaperData?.tenantTotalPaperSavedThisMonth
            ? 'scp.dashboard.sustainability.saved_paper_message_less'
            : 'scp.dashboard.sustainability.saved_paper_no_data_message'
          )
            | translate
              : { value: savedPaperData?.tenantTotalPaperSavedThisMonth || 0, value1: savedPaperData?.tenantPaperSavedAgainstLastMonth || 0 | AbsoluteValue }
        "
      ></div>

      <div
        class="scp_sustainability_community_position"
        [innerHTML]="
          (savedPaperData?.tenantPaperSavedRankInSynappxPercentage
            ? 'scp.dashboard.sustainability.community_position_message'
            : 'scp.dashboard.sustainability.community_position_no_data_message'
          ) | translate : { value: savedPaperData?.tenantPaperSavedRankInSynappxPercentage || 0 }
        "
      ></div>

      <button type="button" mat-flat-button color="primary" (click)="showSetupGuideDialog()">{{ 'scp.dashboard.setup_guide_button_text' | translate }}</button>
    </div>
  </div>

  <mat-card-content class="my-4">
    <div class="fx_rowwarp fx_startstretch">
      <mat-card class="scp_dashboard_count_card flex_Card_3">
        <mat-icon class="text-primary" [routerLink]="SCPRouterLinks.users">supervised_user_circle</mat-icon>
        <mat-card-title>{{ 'scp.common.users' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'scp.common.total' | translate }}: {{ computedData.users.total }}</mat-card-subtitle>
        <mat-card-subtitle>{{ 'scp.common.active' | translate }}: {{ computedData.users.active }}</mat-card-subtitle>
        <mat-card-subtitle>{{ 'scp.common.inactive' | translate }}: {{ computedData.users.inActive }}</mat-card-subtitle>
      </mat-card>

      <mat-card class="scp_dashboard_count_card flex_Card_3">
        <mat-icon class="text-success" [routerLink]="SCPRouterLinks.devices">print</mat-icon>
        <mat-card-title>{{ 'scp.common.devices' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'scp.common.total' | translate }}: {{ computedData.devices.total }}</mat-card-subtitle>
        <mat-card-subtitle>{{ 'scp.common.active' | translate }}: {{ computedData.devices.active }}</mat-card-subtitle>
        <mat-card-subtitle>{{ 'scp.common.inactive' | translate }}: {{ computedData.devices.inActive }}</mat-card-subtitle>
      </mat-card>

      <mat-card class="scp_dashboard_count_card flex_Card_3">
        <mat-icon class="text-warning" [routerLink]="SCPRouterLinks.devices">subscriptions</mat-icon>
        <mat-card-title>{{ 'scp.common.licenses' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'scp.common.total' | translate }}: {{ computedData.licenses.total }}</mat-card-subtitle>
        <mat-card-subtitle>{{ 'scp.common.assigned' | translate }}: {{ computedData.licenses.active }}</mat-card-subtitle>
        <mat-card-subtitle>{{ 'scp.common.unassigned' | translate }}: {{ computedData.licenses.inActive }}</mat-card-subtitle>
      </mat-card>
    </div>
  </mat-card-content>

  <mat-card-content>
    <div class="fx_rowwarp fx_startstretch">
      <mat-card class="scp_dashboard_count_card flex_Card_2">
        <mat-card-content class="fx_rownowarp fx_centerstretch">
          <div class="fxFlex_50">
            <canvas id="totalPagesOutputChart">{{ totalPagesOutputChart }}</canvas>
          </div>
          <div class="fxFlex_50">
            <canvas id="monthlyPagesOutputChart">{{ monthlyPagesOutputChart }}</canvas>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card class="scp_dashboard_count_card flex_Card_2">
        <mat-card-content class="fx_rownowarp fx_centerstretch">
          <div class="fxFlex_100">
            <canvas id="yearlyPagesOutputChart">{{ yearlyPagesOutputChart }}</canvas>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
