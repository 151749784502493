<div class="scp_theme mat-typography">
  <h2 mat-dialog-title>{{ 'scp.reports.execute.export.dialog_title' | translate }}</h2>

  <form novalidate autocomplete="off" validateFormAndScrollToFirstError [formGroup]="executeFormGroup" (ngSubmit)="exportReport()">
    <mat-dialog-content>
      <h3>{{ reportName }}</h3>
      <div class="fx_columnwarp fx_gap_15">
        <mat-form-field class="mb-3">
          <mat-label>{{ 'scp.reports.execute.export.fields.date_range' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
            <input matStartDate placeholder="{{ 'scp.reports.execute.export.fields.start_date_range' | translate }}" [formControl]="fromDateFormCtrl" />
            <input matEndDate placeholder="{{ 'scp.reports.execute.export.fields.end_date_range' | translate }}" [formControl]="toDateFormCtrl" />
          </mat-date-range-input>
          <mat-error *ngIf="fromDateFormCtrl.hasError('required')"> {{ 'scp.reports.execute.export.validations.start_date_range' | translate }} </mat-error>
          <mat-error *ngIf="toDateFormCtrl.hasError('required')"> {{ 'scp.reports.execute.export.validations.end_date_range' | translate }} </mat-error>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-radio-group [formControl]="exportTypeFormCtrl" class="fx_gap_15">
          <label>{{ 'scp.reports.execute.export.fields.export_format' | translate }}</label>
          <mat-radio-button value="pdf">{{ 'scp.reports.execute.export.fields.export_pdf_format' | translate }}</mat-radio-button>
          <mat-radio-button value="xlsx">{{ 'scp.reports.execute.export.fields.export_excel_format' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button mat-dialog-close>{{ 'scp.common.cancel_button_text' | translate }}</button>
      <button type="submit" mat-flat-button color="primary" [disabled]="isSubmitting">
        {{ 'scp.reports.execute.export.export_button_text' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
