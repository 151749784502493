import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { Papa } from 'ngx-papaparse';

import { BaseComponent } from '../../../shared/components/base.component';
import { LogHandlerService } from '../../../shared/services/ssp/log-handler.service';
import { NoticeComponent } from '../../../scp-shared/dialogs/notice/notice.component';

const FILE_SIZE_LIMIT = 512000; // 500KB (1 KB = 1024 bytes)

export interface CSVUser {
  displayname: string;
  firstname: string;
  lastname: string;
  username: string;
}

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.scss'],
})
export class ImportUsersComponent extends BaseComponent implements OnInit {
  isSubmitting = false;
  searchFormGroup: FormGroup;
  importTypeFormCtrl: FormControl;
  searchByGroupsFormCtrl: FormControl;
  searchByTypeFormCtrl: FormControl;
  searchTermFormCtrl: FormControl;
  selectedItemFormCtrl: FormControl;

  filteredOptions: Observable<[]>;
  searchTerm$ = new BehaviorSubject<string>(null);
  searchResults = [];
  selectedUser;

  flag: boolean;
  importUserFormGroup: FormGroup;
  notesFormCtrl: FormControl;
  fileInputFormCtrl: FormControl;

  domains: string[] = [];
  csvData = [];
  validationMessage = [];
  reqExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  isGoogle: boolean;

  isIDPImportDisable: boolean;
  isCSVImportDisable: boolean;

  constructor(private dialogRef: MatDialogRef<ImportUsersComponent>, private papa: Papa, private logHandlerService: LogHandlerService) {
    super();

    this.importTypeFormCtrl = new FormControl('idp');
    this.searchByGroupsFormCtrl = new FormControl(false); // Should search by GROUPS?
    this.searchByTypeFormCtrl = new FormControl('byUserName'); // Search by user/group/email?
    this.searchTermFormCtrl = new FormControl(null, [Validators.required]); // User OR Group name
    this.selectedItemFormCtrl = new FormControl(null, [Validators.required]); // Selected value from auto complete

    this.searchFormGroup = new FormGroup({
      searchByGroup: this.searchByGroupsFormCtrl,
      searchByType: this.searchByTypeFormCtrl,
      searchTerm: this.searchTermFormCtrl,
      selectedItem: this.selectedItemFormCtrl,
    });

    this.notesFormCtrl = new FormControl('');
    this.fileInputFormCtrl = new FormControl(null, [Validators.required]);

    this.importUserFormGroup = new FormGroup({
      notes: this.notesFormCtrl,
      fileInput: this.fileInputFormCtrl,
    });

    this.isGoogle = this.appService.isGoogleWorkspace();

    this.isIDPImportDisable = false;
    this.isCSVImportDisable = true;
  }

  ngOnInit() {
    this.appService.refreshSupportedDomain();
    this.domains = (this.appService.supportedDomains || []).slice();
    this.searchByGroupsFormCtrl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((isSelected) => {
      this.searchByTypeFormCtrl.setValue(isSelected ? 'byGroupName' : 'byUserName');
    });

    this.searchByTypeFormCtrl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.searchTermFormCtrl.reset();
      this.searchFormGroup.markAsPristine();

      this.searchTerm$.next('');
    });

    this.searchTerm$
      .pipe(
        this.takeUntilDestroyed(),
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          this.searchResults = [];
          this.selectedUser = null;
          this.selectedItemFormCtrl.reset();
        }),
        filter((val) => (val || '').trim().length > 0),
        switchMap((searchTerm) => {
          return this.appService.findUsers(!!this.searchByGroupsFormCtrl.value, this.searchByTypeFormCtrl.value === 'byEmail', searchTerm);
        })
      )
      .subscribe((userList: Array<any>) => {
        if (!!this.searchByGroupsFormCtrl.value) {
          this.searchResults = userList.filter((x) => this.appService.supportedDomains.includes(x.groupemail.split('@')[1].toLowerCase()));
        } else {
          this.searchResults = userList.filter((x) => this.appService.supportedDomains.includes(x.username.split('@')[1].toLowerCase()));
        }
      });
  }

  onSelectImportIDP() {
    this.isIDPImportDisable = false;
    this.isCSVImportDisable = true;

    this.fileInputFormCtrl.setValue(null);
    this.importUserFormGroup.controls['fileInput'].setValue('');
  }

  onSelectImportCSV() {
    this.isIDPImportDisable = true;
    this.isCSVImportDisable = false;

    this.searchTermFormCtrl.setValue(null);
    this.selectedItemFormCtrl.setValue(null);
  }

  selectItem(selectedItem) {
    this.selectedItemFormCtrl.setValue(selectedItem);

    if (!!this.searchByGroupsFormCtrl.value) {
      if (this.searchByTypeFormCtrl.value === 'byEmail') {
        this.searchTermFormCtrl.setValue(selectedItem.groupemail);
      } else {
        this.searchTermFormCtrl.setValue(selectedItem.groupname);
      }
    } else {
      if (this.searchByTypeFormCtrl.value === 'byEmail') {
        this.searchTermFormCtrl.setValue(selectedItem.username);
      } else {
        this.searchTermFormCtrl.setValue(selectedItem.displayname);
      }
    }

    this.searchResults = [];
  }

  parseComplete(results) {
    const importUserArray: CSVUser[] = results;
    const emailIds = [];
    const userNames = [];
    const errorMessages = [];
    let indexPosition = 2;
    if (importUserArray.length > 0) {
      for (const importUser of importUserArray) {
        if (
          importUser.username === undefined ||
          importUser.firstname === undefined ||
          importUser.lastname === undefined ||
          importUser.displayname === undefined
        ) {
          this.validationMessage = [this.translate.instant('scp.users.upsert.import_user_validate_invalid_file')];
          return;
        } else {
          if (this.isGoogle) {
            this.validateGsuiteRow(importUser, errorMessages, indexPosition, emailIds);
          } else {
            this.validateO365Row(importUser, errorMessages, indexPosition, userNames, emailIds);
          }
          emailIds.push(importUser.username);
          userNames.push(importUser.displayname);
          indexPosition++;
        }
      }
      this.validationMessage = errorMessages;
      this.csvData = importUserArray;
    } else {
      this.validationMessage = [this.translate.instant('scp.users.upsert.import_user_validate_invalid_file')];
      return;
    }
  }
  validateO365Row(csvUser: CSVUser, errorMessages: any[], indexPosition: number, userNames: any[], emailIds: any[]) {
    // validation on DisplayName of csv File
    if (!csvUser.displayname || csvUser.displayname.trim() === '') {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_display_name_null', {
          indexPosition: indexPosition,
        })
      );
    } else if (userNames.indexOf(csvUser.displayname.toString()) > -1) {
      const duplication = userNames.indexOf(csvUser.displayname.toString()) + 2;
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_display_name_dup', {
          indexPosition: indexPosition,
          duplication: duplication,
        })
      );
    }
    // validation on UserPrincipleName of csv File
    if (!csvUser.username || csvUser.username.trim() === '') {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_user_principal_name_null', {
          indexPosition: indexPosition,
        })
      );
    } else if (!this.domains.includes(csvUser.username.toLowerCase().split('@')[1])) {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_user_principal_name_unsupported_domain', {
          indexPosition: indexPosition,
          userDomain: this.domains.join(', '),
        })
      );
    } else if (!this.reqExp.test(csvUser.username.toString())) {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_user_principal_name_invalid', {
          indexPosition: indexPosition,
        })
      );
    } else if (emailIds.indexOf(csvUser.username.toString()) > -1) {
      const duplocation = emailIds.indexOf(csvUser.username.toString()) + 2;
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_user_principal_name_dup', {
          indexPosition: indexPosition,
          duplocation: duplocation,
        })
      );
    }
  }
  validateGsuiteRow(gSuiteUser: CSVUser, errorMessages: any[], indexPosition: number, emailIds: any[]) {
    // validation on First Name and Last Name of csv File
    if (!gSuiteUser.displayname || gSuiteUser.displayname.trim() === '') {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_first_last_name_null', {
          indexPosition: indexPosition,
        })
      );
    }
    // validation on Email Address of csv File
    if (!gSuiteUser.username || gSuiteUser.username.trim() === '') {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_email_address_null', {
          indexPosition: indexPosition,
        })
      );
    } else if (!this.domains.includes(gSuiteUser.username.toLowerCase().split('@')[1])) {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_email_address_unsupported_domain', {
          indexPosition: indexPosition,
          userDomain: this.domains.join(', '),
        })
      );
    } else if (!this.reqExp.test(gSuiteUser.username.toString())) {
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_email_address_invalid', {
          indexPosition: indexPosition,
        })
      );
    } else if (emailIds.indexOf(gSuiteUser.username.toString()) > -1) {
      const duplocation = emailIds.indexOf(gSuiteUser.username.toString()) + 2;
      errorMessages.push(
        this.translate.instant('scp.users.upsert.import_user_validate_email_address_dup', {
          indexPosition: indexPosition,
          duplocation: duplocation,
        })
      );
    }
  }

  onFilesAdded(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.importUserFormGroup.controls['fileInput'].setValue(file ? file.name : ''); // <-- Set Value for Validation
      if (!file.name.endsWith('.csv')) {
        this.validationMessage = [this.translate.instant('scp.users.upsert.import_user_validate_only_csv')];
        return;
      }
      if (file.size > FILE_SIZE_LIMIT) {
        this.validationMessage = [this.translate.instant('scp.users.upsert.import_user_validate_file_limit_reached')];
        return;
      }
      this.csvData = [];
      const parent = this;
      if (this.isGoogle) {
        this.papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            results = results.data.map((row) => ({
              username: row['Email Address [Required]'],
              displayname: row['First Name [Required]'] + ' ' + row['Last Name [Required]'],
              firstname: row['First Name [Required]'],
              lastname: row['Last Name [Required]'],
            }));
            parent.parseComplete(results);
          },
        });
      } else {
        this.papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            results = results.data.map((row) => {
              const updatedRes = {
                username: row['userPrincipalName'],
                displayname: row['displayName'],
                firstname: row['givenName'],
                lastname: row['surname'],
              };
              return updatedRes;
            });
            parent.parseComplete(results);
          },
        });
      }
    }
  }

  onClick(event) {
    event.srcElement.value = '';
    this.importUserFormGroup.controls['fileInput'].setValue('');
    this.validationMessage = [];
  }

  insertUser() {
    if (this.importTypeFormCtrl.value === 'idp') {
      if (!!this.searchByGroupsFormCtrl.value) {
        const selectedUserGroup = this.searchFormGroup.value.selectedItem;

        this.isSubmitting = true;

        this.appService
          .createUserFromGroup({
            groupname: selectedUserGroup.groupname,
            groupemail: selectedUserGroup.groupemail,
            groupid: selectedUserGroup.groupid,
          })
          .then((res) => {
            this.isSubmitting = true;

            this.appService
              .createUser(res)
              .pipe(this.takeUntilDestroyed())
              .subscribe(
                (res) => {
                  const msgAdded = res.added.map((user) => `${user.displayname}: ${this.translate.instant('scp.users.upsert.import_user_added_message')}`);
                  const msgExists = res.skipped.map((user) => `${user.displayname}: ${this.translate.instant('scp.users.upsert.import_user_already_added')}`);
                  const msgErrors = res.errors.map((user) => `${user.displayname}: ${this.translate.instant('scp.users.upsert.import_user_added_failed')}`);
                  const actions = [].concat(msgAdded, msgExists, msgErrors);
                  this.openNotice(actions);

                  this.dialogRef.close(true);
                },
                (err: HttpErrorResponse) => {
                  this.isSubmitting = false;

                  this.logHandlerService.addSystemLogCustom('User(s) data failed to save (ST00020)', 'Users', 'Import user', 'SCP-Portal-ST00020');
                  let errMsg = 'scp.users.upsert.save_error_message';
                  this.appService.showError(this.translate.instant(errMsg));
                }
              );
          });
      } else {
        const selectedUser = this.searchFormGroup.value.selectedItem;

        this.isSubmitting = true;

        this.appService
          .createUser([
            {
              displayname: selectedUser.displayname,
              firstname: selectedUser.firstname || '',
              lastname: selectedUser.lastname || '',
              username: selectedUser.username,
            },
          ])
          .pipe(this.takeUntilDestroyed())
          .subscribe(
            (res) => {
              if (res.skipped.length !== 0) {
                let errMsg = 'scp.users.upsert.save_user_already_exists_error_message';
                this.appService.showError(this.translate.instant(errMsg));
              } else if (res.errors.length !== 0) {
                this.logHandlerService.addSystemLogCustom('User(s) data failed to save (ST00020)', 'Users', 'save user', 'SCP-Portal-ST00020');
                let errMsg = 'scp.users.upsert.save_error_message';
                this.appService.showError(this.translate.instant(errMsg));
              }
              this.dialogRef.close(true);
            },
            (err: HttpErrorResponse) => {
              this.isSubmitting = false;
              this.logHandlerService.addSystemLogCustom('User(s) data failed to save (ST00020)', 'Users', 'save user', 'SCP-Portal-ST00020');
              let errMsg = 'scp.users.upsert.save_error_message';
              this.appService.showError(this.translate.instant(errMsg));
            }
          );
      }
    } else {
      this.isSubmitting = true;

      this.appService
        .createUser(this.csvData)
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          (res) => {
            const msgAdded = res.added.map((user) => `${user.displayname}: ${this.translate.instant('scp.users.upsert.import_user_added_message')}`);
            const msgExists = res.skipped.map((user) => `${user.displayname}: ${this.translate.instant('scp.users.upsert.import_user_already_added')}`);
            const msgErrors = res.errors.map((user) => `${user.displayname}: ${this.translate.instant('scp.users.upsert.import_user_added_failed')}`);
            const actions = [].concat(msgAdded, msgExists, msgErrors);
            this.openNotice(actions);

            this.dialogRef.close(true);
          },
          (err: HttpErrorResponse) => {
            this.isSubmitting = false;
            this.logHandlerService.addSystemLogCustom('User(s) data failed to save (ST00020)', 'Users', 'save user', 'SCP-Portal-ST00020');
            let errMsg = 'scp.users.upsert.save_error_message';
            this.appService.showError(this.translate.instant(errMsg));
          }
        );
    }
  }

  openNotice(actions) {
    this.dialog.open(NoticeComponent, {
      width: '50%',
      minHeight: '20%',
      data: {
        noticeinfo: actions,
      },
    });
  }

  isDisabled(): boolean {
    if (this.importTypeFormCtrl.value === 'idp') {
      if (this.searchFormGroup.invalid) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.importUserFormGroup.invalid || this.validationMessage.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
}
