<mat-card>
  <mat-card-actions class="mb-0 fx_space-between-end">
    <mat-form-field>
      <mat-label> {{ 'scp.jobs.queue.filter_queue_jobs_search_text' | translate }} </mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" />
    </mat-form-field>

    <button mat-icon-button matTooltip="{{ 'scp.common.refresh_data_button_text' | translate }}" (click)="getPrintQueueJobsData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-card-content>
    <div class="table-responsive mat-elevation-z8">
      <table mat-table matSort matSortActive="fileCreatedTime" matSortDirection="desc" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="fileCreatedTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.queue.columns.created_at' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.formattedFileCreatedTime }}</td>
        </ng-container>

        <ng-container matColumnDef="userDisplayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.queue.columns.user_display_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.userDisplayName }}</td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.queue.columns.file_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.fileName }}</td>
        </ng-container>

        <ng-container matColumnDef="jobLocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.queue.columns.job_location' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.jobLocation || 'Office' }}</td>
        </ng-container>

        <ng-container matColumnDef="pageCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.queue.columns.page_count' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.pageCount }}</td>
        </ng-container>

        <ng-container matColumnDef="colorMode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.queue.columns.color_mode' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.colorMode }}</td>
        </ng-container>

        <ng-container matColumnDef="duplex">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.queue.columns.duplex' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ (!!row.duplex ? 'scp.common.yes' : 'scp.common.no') | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>{{ 'scp.jobs.queue.columns.actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="showDeleteJobDialog(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>

<ng-template let-data #sustainabilitySnackbarTemplate>
  <div class="fx_startcenter fx_gap_8">
    <img src="/assets/scp/images/SCP_leaf_large.png" width="24px" />
    <p class="m-0">Thank you for making sustainable print choices.</p>
  </div>
</ng-template>
