<form
  novalidate
  autocomplete="off"
  validateFormAndScrollToFirstError
  [formGroup]="deviceFormGroup"
  class="scp_theme mat-typography"
  (ngSubmit)="upsertDevice()"
>
  <h2 mat-dialog-title>{{ (isAdd ? 'scp.devices.upsert.dialog_add_title' : 'scp.devices.upsert.dialog_edit_title') | translate }}</h2>

  <mat-dialog-content>
    <div class="fx_columnwarp fx_gap_0">
      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.type' | translate }} </mat-label>
        <mat-select [formControl]="typeFormCtrl" panelClass="scp_theme">
          <mat-option value="OSA">OSA</mat-option>
          <mat-option value="LUNA">ESF</mat-option>
        </mat-select>
        <mat-error *ngIf="typeFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.alias' | translate }} </mat-label>
        <input matInput [formControl]="nameFormCtrl" />
        <mat-error *ngIf="nameFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="nameFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.alpha_numeric_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.ip' | translate }} </mat-label>
        <input matInput [formControl]="ipFormCtrl" />
        <mat-error *ngIf="ipFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="ipFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.ip_address_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.uuid' | translate }} </mat-label>
        <input matInput [formControl]="uuidFormCtrl" />
        <mat-error *ngIf="uuidFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.machine_id' | translate }} </mat-label>
        <input matInput [formControl]="machineIdFormCtrl" />
        <mat-error *ngIf="machineIdFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="machineIdFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.alpha_numeric_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.mac_address' | translate }} </mat-label>
        <input matInput [formControl]="macFormCtrl" />
        <mat-error *ngIf="macFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="macFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.mac_address_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.model' | translate }} </mat-label>
        <input matInput [formControl]="modelFormCtrl" />
        <mat-error *ngIf="modelFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="modelFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.alpha_numeric_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.serial' | translate }} </mat-label>
        <input matInput [formControl]="serialFormCtrl" />
        <mat-error *ngIf="serialFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="serialFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.numeric_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.os' | translate }} </mat-label>
        <input matInput [formControl]="osFormCtrl" />
        <mat-error *ngIf="osFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="osFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.numeric_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.osa_app_id' | translate }} </mat-label>
        <input matInput [formControl]="osaAppIdFormCtrl" />
        <mat-error *ngIf="osaAppIdFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="osaAppIdFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.alpha_numeric_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label> {{ 'scp.devices.upsert.fields.location' | translate }} </mat-label>
        <input matInput [formControl]="locationFormCtrl" />
        <mat-error *ngIf="locationFormCtrl.hasError('required')"> {{ 'scp.common.validations.required' | translate }} </mat-error>
        <mat-error *ngIf="locationFormCtrl.hasError('pattern')"> {{ 'scp.devices.upsert.validations.alpha_numeric_pattern' | translate }} </mat-error>
      </mat-form-field>

      <mat-label> {{ 'scp.devices.upsert.fields.allow_direct_print' | translate }} </mat-label>
      <mat-slide-toggle [formControl]="directPrintFormCtrl" (change)="updateDirectPrintEnabled()"> </mat-slide-toggle>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>{{ 'scp.common.cancel_button_text' | translate }}</button>
    <button type="submit" mat-flat-button color="primary" [disabled]="isSubmitting">{{ 'scp.common.save_button_text' | translate }}</button>
  </mat-dialog-actions>
</form>
