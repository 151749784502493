import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { rrulestr } from 'rrule';
import { iif } from 'rxjs';

import { BaseComponent } from '../../../shared/components/base.component';
import { LogHandlerService } from '../../../shared/services/ssp/log-handler.service';

@Component({
  selector: 'app-reports-schedule-upsert',
  templateUrl: './reports-schedule-upsert.component.html',
  styleUrls: ['./reports-schedule-upsert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsScheduleUpsertComponent extends BaseComponent implements OnInit {
  isNew = false;
  isSubmitting = false;

  currentDate;
  yesterday;
  minDate;
  maxDate;

  scheduleGuid: string;

  showSpinners = true;
  showSeconds = false;
  touchUi = false;
  enableMeridian = false;

  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  daysOfMonthList = [];
  reportList = [];
  updatedRecipientList = [];

  reportScheduleFormGroup: FormGroup;
  reportNameFormCtrl: FormControl;
  newScheduleNameFormCtrl: FormControl;
  scheduleStartDateTimeFormCtrl: FormControl;
  scheduleEndDateTimeFormCtrl: FormControl;
  exportTypeFormCtrl: FormControl;
  occurrenceFormCtrl: FormControl;
  frequencyFormCtrl: FormControl;
  daysOfWeekFormCtrl: FormControl;
  daysOfMonthFormCtrl: FormControl;
  recipientsFormCtrl: FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<ReportsScheduleUpsertComponent>,
    private logHandlerService: LogHandlerService
  ) {
    super();

    this.isNew = !data;

    if (this.isNew) {
      this.newScheduleNameFormCtrl = new FormControl('', [Validators.required]);
      this.reportNameFormCtrl = new FormControl('', [Validators.required]);
      this.scheduleStartDateTimeFormCtrl = new FormControl('', [Validators.required]);
      this.scheduleEndDateTimeFormCtrl = new FormControl('', [Validators.required]);
      this.exportTypeFormCtrl = new FormControl('pdf', [Validators.required]);
      this.occurrenceFormCtrl = new FormControl('2');
      this.frequencyFormCtrl = new FormControl('DAILY');
      this.daysOfWeekFormCtrl = new FormControl([]);
      this.daysOfMonthFormCtrl = new FormControl('1');
      this.recipientsFormCtrl = new FormControl([], [Validators.required]);
    } else {
      this.scheduleGuid = data?.guid;
      this.newScheduleNameFormCtrl = new FormControl(data?.name, [Validators.required]);
      this.reportNameFormCtrl = new FormControl(data?.reportTemplateGroup.guid, [Validators.required]);
      this.scheduleStartDateTimeFormCtrl = new FormControl(data?.fromDttm, [Validators.required]);
      this.scheduleEndDateTimeFormCtrl = new FormControl(data?.toDttm, [Validators.required]);
      this.exportTypeFormCtrl = new FormControl(data?.exportFormat, [Validators.required]);
      this.occurrenceFormCtrl = new FormControl('2');
      this.frequencyFormCtrl = new FormControl(this.getFrequencyFromRRule(data?.recurrenceRule));
      this.daysOfWeekFormCtrl = new FormControl(this.getWeekDayFromRRule(data?.recurrenceRule));
      this.daysOfMonthFormCtrl = new FormControl(this.getMonthDayFromRRule(data?.recurrenceRule));
      this.recipientsFormCtrl = new FormControl(data?.recipients, [Validators.required]);
    }

    this.reportScheduleFormGroup = new FormGroup({
      fromDttm: this.scheduleStartDateTimeFormCtrl,
      toDttm: this.scheduleEndDateTimeFormCtrl,
      reportTemplateGroup: this.reportNameFormCtrl,
      name: this.newScheduleNameFormCtrl,
      renderer: this.exportTypeFormCtrl,
      recipients: this.recipientsFormCtrl,
      occurrence: this.occurrenceFormCtrl,
      frequency: this.frequencyFormCtrl,
      daysOfWeek: this.daysOfWeekFormCtrl,
      daysOfMonth: this.daysOfMonthFormCtrl,
    });

    this.currentDate = moment();
    if (this.isNew) {
      this.minDate = moment(this.currentDate);
    } else {
      this.minDate = moment(data?.fromDttm);
    }
    this.maxDate = moment(this.currentDate);
    this.yesterday = moment(this.currentDate.subtract(1, 'days'));
  }

  ngOnInit() {
    this.getReportsData();
  }

  hasTranslation(key) {
    const translation = this.translate.instant(key);
    return translation !== key && translation !== '';
  }

  // RRule
  getFrequencyFromRRule = (rrule) => {
    if (rrule !== undefined) {
      let result = rrulestr(rrule).origOptions.freq;

      return ['YEARLY', 'MONTHLY', 'WEEKLY', 'DAILY'][result];
    }

    return '';
  };

  getWeekdayName = (value) => {
    return ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'][value];
  };

  getWeekDayFromRRule = (rrule) => {
    if (rrule !== undefined) {
      let result = rrulestr(rrule).origOptions.byweekday;

      if (result !== undefined) {
        return result.toString().split(',');
      }
    }
  };

  getMonthDayFromRRule = (rrule) => {
    if (rrule !== undefined) {
      let resultString = '';
      let result = rrulestr(rrule).origOptions.bymonthday;

      if (result !== undefined) {
        resultString = result.toString();
      }

      return resultString;
    }

    return '';
  };

  createRRule = () => {
    let rrule = 'RRULE:FREQ=' + this.frequencyFormCtrl.value;

    if (this.frequencyFormCtrl.value === 'WEEKLY') {
      if (this.daysOfWeekFormCtrl.value !== '') {
        rrule = rrule + ';BYDAY=' + this.daysOfWeekFormCtrl.value.toString();
      }
    }

    if (this.frequencyFormCtrl.value === 'MONTHLY') {
      if (this.daysOfMonthFormCtrl.value !== '') {
        rrule = rrule + ';BYMONTHDAY=' + this.daysOfMonthFormCtrl.value + ';BYSETPOS=-1';
      }
    }

    return rrule;
  };

  compare(c1: any, c2: any) {
    if (c1 == c2) return true;
    else return false;
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      if (this.validateEmail(value)) {
        this.recipientsFormCtrl.setValue([...this.recipientsFormCtrl.value, value.trim()]);
        this.recipientsFormCtrl.updateValueAndValidity();
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(email: string): void {
    const index = this.recipientsFormCtrl.value.indexOf(email);

    if (index >= 0) {
      this.updatedRecipientList = this.recipientsFormCtrl.value.slice();
      this.updatedRecipientList.splice(index, 1);
      this.recipientsFormCtrl.setValue(this.updatedRecipientList);
      this.recipientsFormCtrl.updateValueAndValidity();
    }
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  getReportsData() {
    this.appService
      .getReports()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.reportList = data;
      });
  }

  upsertReportSchedule() {
    if (this.reportScheduleFormGroup.valid) {
      this.isSubmitting = true;

      let fromDttm = moment(this.scheduleStartDateTimeFormCtrl.value);
      let toDttm = moment(this.scheduleEndDateTimeFormCtrl.value);
      fromDttm.set({ second: 0, millisecond: 0 });
      toDttm.set({ second: 0, millisecond: 0 });

      iif(
        () => this.isNew, // prettier-ignore
        this.appService.createReportSchedule({
          recurrenceRule: this.createRRule(),
          fromDttm: fromDttm.utc(),
          toDttm: toDttm.utc(),
          reportTemplateGroup: { guid: this.reportNameFormCtrl.value },
          name: this.newScheduleNameFormCtrl.value,
          reportFromDttm: this.scheduleStartDateTimeFormCtrl.value,
          fromOffset: 'P0D',
          fromPreviousRelativeFl: true,
          toOffset: 'P0D',
          toPreviousRelativeFl: false,
          renderer: this.exportTypeFormCtrl.value,
          schedulePreset: '',
          recipients: this.recipientsFormCtrl.value,
        }),
        this.appService.updateReportSchedule(
          {
            recurrenceRule: this.createRRule(),
            fromDttm: fromDttm.utc(),
            toDttm: toDttm.utc(),
            reportTemplateGroup: { guid: this.reportNameFormCtrl.value },
            name: this.newScheduleNameFormCtrl.value,
            reportFromDttm: this.scheduleStartDateTimeFormCtrl.value,
            fromOffset: 'P0D',
            fromPreviousRelativeFl: true,
            toOffset: 'P0D',
            toPreviousRelativeFl: false,
            renderer: this.exportTypeFormCtrl.value,
            schedulePreset: '',
            recipients: this.recipientsFormCtrl.value,
          },
          this.scheduleGuid
        )
      )
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.dialogRef.close(true);
          },
          () => {
            this.logHandlerService.addSystemLogCustom('Report schedule failed to save (ST00029)', 'Reports', 'save report schedule', 'SCP-Portal-ST00029');
            this.appService.showError(this.translate.instant('scp.reports.schedule.upsert.save_error_message'));

            this.isSubmitting = false;
          }
        );
    }
  }
}
