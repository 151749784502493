import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { globalAppData } from '../interfaces/AppData';
import { AuthRet, AuthService } from './auth.service';
import { IdpCommonService } from './idp-common.service';
import { DateFormatService } from './datetime-format.service';
import { environment } from './../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class CloudPrintRuntimeService {
    idpTokenAvailable: BehaviorSubject<any>;
    loginSuccess: BehaviorSubject<any>;
    authResultAvailable: BehaviorSubject<any>;
    appLanguage = this.authService.getBrowserLanguage();
    getLanguage: any = this.dateFormatatService.getLanguage();
    constructor(
        private authService: AuthService,
        private idpCommonService: IdpCommonService,
        private dateFormatatService:DateFormatService
    ) {
        this.idpTokenAvailable = new BehaviorSubject<any>(null);
        this.loginSuccess = new BehaviorSubject<any>(null);
        this.authResultAvailable = new BehaviorSubject<any>(null);
        // Initialize the static items
        globalAppData.setStatic('logged-in-user-name', this.authService.UserName);
        globalAppData.setStatic('logged-in-user-upn', this.authService.userUpn);
        globalAppData.setStatic('logged-in-user-email', this.authService.userEmail);
        globalAppData.setStatic('scp-role', this.authService.cpUserRole);
        globalAppData.setStatic('tenant-tier', this.authService.tenantTier);
        globalAppData.setStatic('auth0-application-type', this.authService.applicationType);
        globalAppData.setStatic('auth0-connection-name', this.authService.getConnectionName);
        globalAppData.setStatic('tenant-info', this.authService.tenantInfo);
        globalAppData.setStatic('auth-config', environment.AuthConfig);
        globalAppData.setStatic('admin-roles', this.authService.adminRole);
        globalAppData.setStatic('rmmadmin-roles', this.authService.rmmAdminRole);
        // For browserLanguage
        globalAppData.setDynamic('app-Lang', this.appLanguage);
        globalAppData.setDynamic('browser-language-and-region',this.getLanguage);

        // Currently meeting & go are combined as single product: Go
        globalAppData.setStatic('auth-view-go', (this.authService.viewGo));
        globalAppData.setStatic('auth-view-meeting', (this.authService.viewMeeting));
        globalAppData.setStatic('auth-view-rmm', this.authService.viewRmm);
        globalAppData.setStatic('auth-view-scp',this.authService.viewScp);
        // Initialize the dynamic items
        globalAppData.setDynamic('auth-info', of(null), this.loginSuccess);
        globalAppData.setDynamic('auth-result', of(null), this.authResultAvailable);
        globalAppData.setDynamic('idp-token', of(null), this.idpTokenAvailable);
        globalAppData.setStatic('home-domain', this.authService.homeDomain);
        // Inject the auth result
        this.authService.loginSuccess.subscribe((authRet: any) => {
            if (authRet) {
                const { authResult } = authRet;
                globalAppData.setDynamic('auth-result', of(authResult), this.authResultAvailable);
                this.authResultAvailable.next(authResult);
            }
        });
        // Subscribe to the loginSuccess event of the AuthService
        // Upon receiving a valid loginSuccess event, inject runtime data into cloud-print library
        this.authService.baDetailsAvailable.subscribe({
            next: (authRet: AuthRet) => {
                if (authRet && this.authService.isCloudPrint) {
                    this.injectGeneralDetails();
                }
            }
        });
        this.authService.renewIdpToken.subscribe((token)=>{
            if(token){
                globalAppData.setDynamic('idp-token', of(token), this.idpTokenAvailable);
                this.idpTokenAvailable.next(token);
            }
        });
        this.authService.renewAccessToken.subscribe((authRet: any) => {
            if (authRet) {
                const { authResult } = authRet;
                globalAppData.setDynamic('auth-result', of(authResult), this.authResultAvailable);
                this.authResultAvailable.next(authResult);
            }
        })
       }

    /**
     * Inject all runtime data into cloud print library
     */
    injectGeneralDetails() {
        // Auth0 authentication information
        globalAppData.setStatic('logged-in-user-name', this.authService.UserName);
        globalAppData.setStatic('logged-in-user-upn', this.authService.userUpn);
        globalAppData.setStatic('logged-in-user-email', this.authService.userEmail);
        globalAppData.setStatic('scp-role', this.authService.cpUserRole);
        globalAppData.setStatic('tenant-tier', this.authService.tenantTier);
        globalAppData.setStatic('auth0-application-type', this.authService.applicationType);
        globalAppData.setStatic('auth0-connection-name', this.authService.getConnectionName);
        globalAppData.setStatic('tenant-info', this.authService.tenantInfo);
        // Currently meeting & go are combined as single product: Go
        globalAppData.setStatic('auth-view-go', (this.authService.viewGo));
        globalAppData.setStatic('auth-view-meeting', (this.authService.viewMeeting));
        globalAppData.setStatic('auth-view-rmm', this.authService.viewRmm);
        globalAppData.setStatic('auth-view-scp', this.authService.viewScp);
        globalAppData.setDynamic('auth-info', of(this.authService.accessToken));
        this.loginSuccess.next(this.authService.accessToken);
        // IDP (office 365) authentication information
        if(['waad', 'google-apps'].includes(this.authService.applicationType)) {
            this.idpCommonService.RenewIdpAccessToken().subscribe({
                next: token => {
                    if (token) {
                        globalAppData.setDynamic('idp-token', of(token), this.idpTokenAvailable);
                        this.idpTokenAvailable.next(token);
                    }
                },
                error: err => {
                    // pass empty access token to avoid undefined error in scp code
                    const tokenInfo = {access_token: ""}
                    globalAppData.setDynamic('idp-token', of(tokenInfo), this.idpTokenAvailable);
                    this.idpTokenAvailable.next(tokenInfo);
                }
        });
        }
    }

}

