import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EMPTY, forkJoin, from, iif } from 'rxjs';
import { catchError, concatMap, finalize } from 'rxjs/operators';

import { BaseComponent } from '../../shared/components/base.component';
import { LogHandlerService } from '../../shared/services/ssp/log-handler.service';
import { IDevice } from '../../shared/models/scp-model';
import { DeviceMFPSearchComponent } from './device-mfp-search/device-mfp-search.component';
import { DeviceUpsertComponent } from './device-upsert/device-upsert.component';

import { SubDialogInfo } from '../../scp-shared/interfaces/dialog';
import { DialogService } from '../../scp-shared/services/dialog.service';
import { NotificationService } from '../../shared/services/ssp/notification.service';

const PAGE_NAME = 'DEVICES';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
})
export class DevicesComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filterFormGroup: FormGroup;
  statusFilterFormCtrl: FormControl;
  searchFilterFormCtrl: FormControl;

  selection = new SelectionModel<string>(true, []);
  dataSource = new MatTableDataSource<IDevice>();
  displayedColumns: string[] = ['select', 'activated', 'typeName', 'name', 'model', 'ip', 'serial', 'location', 'directPrintEnabled', 'actions'];
  scpLicenseInfo;

  isSubmitting = false;
  shouldDisableActivateButton = true;
  shouldDisableDeactivateButton = true;

  constructor(private dialogService: DialogService, private notificationService: NotificationService, private logHandlerService: LogHandlerService) {
    super();

    this.statusFilterFormCtrl = new FormControl('all');
    this.searchFilterFormCtrl = new FormControl();

    this.filterFormGroup = new FormGroup({
      status: this.statusFilterFormCtrl,
      searchTerm: this.searchFilterFormCtrl,
    });
  }

  ngOnInit() {
    this.getDevicesData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // Custom column sorting by column's data/value type
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      const currentValue = data[sortHeaderId];

      if (sortHeaderId === 'ip') {
        // Solution for IPv4 address format. eg: "192.168.1.1" becomes "192168001001". What about IPv6 format?
        return currentValue.split('.').reduce((acc, ipPart) => acc + ipPart.padStart(3, '0'), '');
      }

      if (sortHeaderId === 'typeName') {
        return data.type.name;
      }

      return this.defaultSortingDataAccessor(data, sortHeaderId);
    };

    // this.dataSource.sortingDataAccessor = (item, property) => {
    //   switch (property) {
    //     case 'typeName': // Handle nested property address.city
    //       return item.type.name;
    //     default:
    //       return item[property];
    //   }
    // };

    // Reset the page number
    //this.sort.sortChange.pipe(this.takeUntilDestroyed()).subscribe(() => (this.paginator.pageIndex = 0));
    this.sort.sortChange.emit();

    Promise.resolve().then(() => {
      this.paginator.pageSize = this.appService.getPageSizeInfo(PAGE_NAME);
    });

    this.paginator.page.pipe(this.takeUntilDestroyed()).subscribe((evt) => {
      this.appService.savePageSizeInfo(PAGE_NAME, evt.pageSize);
    });

    this.filterFormGroup.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.dataSource.filter = this.filterFormGroup.value;
    });

    this.dataSource.filterPredicate = (data, filter: any) => {
      const { activated, ...otherFields } = data;
      let statusFilter = true;
      let searchTermFilter = true;

      // Clear all checkbox selections
      this.selection.clear();

      if (filter.status !== 'all') {
        statusFilter = activated === filter.status;
      }

      if (filter.searchTerm) {
        searchTermFilter = Object.values(otherFields).toString().toLowerCase().includes(filter.searchTerm.toLowerCase());
      }

      return statusFilter && searchTermFilter;
    };

    this.selection.changed.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.shouldDisableActivateButton = true;
      this.shouldDisableDeactivateButton = true;

      if (this.selection.hasValue()) {
        const [selectedActiveDevices, selectedInactiveDevices] = this.dataSource.filteredData.reduce<[IDevice[], IDevice[]]>(
          (acc, device) => {
            if (this.selection.isSelected(device.guid)) {
              acc[device.activated ? 0 : 1].push(device);
            }
            return acc;
          },
          [[], []]
        );
        if (selectedActiveDevices.length > 0 && selectedInactiveDevices.length > 0) {
          return;
        }

        this.shouldDisableActivateButton = selectedInactiveDevices.length === 0 && selectedActiveDevices.length > 0;
        this.shouldDisableDeactivateButton = selectedActiveDevices.length === 0 && selectedInactiveDevices.length > 0;
      }
    });
  }

  applyFilter(filterString = '') {
    this.dataSource.filter = filterString.trim().toLowerCase();
  }

  isAllRowsSelected() {
    return this.selection.selected.length === this.dataSource.filteredData.length;
  }

  toggleAllRows() {
    if (this.isAllRowsSelected()) {
      return this.selection.clear();
    }

    this.selection.select(...this.dataSource.filteredData.map((device) => device.guid));
  }

  showUpsertDeviceDialog(rowData?) {
    this.dialog
      .open(DeviceUpsertComponent, {
        data: rowData,
        width: '500px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed<boolean>())
      .subscribe((shouldUpdateView) => {
        if (shouldUpdateView) {
          this.getDevicesData();
        }
      });
  }

  showMfpSearchDialog() {
    this.dialog
      .open(DeviceMFPSearchComponent, {
        width: '550px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed<boolean>())
      .subscribe((shouldUpdateView) => {
        if (shouldUpdateView) {
          this.getDevicesData();
        }
      });
  }

  updateActivationStatus(isActivation) {
    this.isSubmitting = true;

    let hasError = false;
    let errorMessage = '';

    from(this.selection.selected)
      .pipe(
        this.takeUntilDestroyed(),
        concatMap((guid) =>
          iif(
            () => isActivation, // prettier-ignore
            this.appService.activatePrinter(guid),
            this.appService.deActivatePrinter(guid)
          ).pipe(
            catchError((err) => {
              let translatedErrorMessage = '';
              if ((err.error = 'SCP Error: license already activated')) {
                this.logHandlerService.addSystemLogCustom('License already activated (ST00007)', 'Devices', 'Device list', 'SCP-Portal-ST00007');
                translatedErrorMessage = this.translate.instant('scp.devices.error_message.license_already_activated');
              } else if ((err.error = 'SCP Error: license already revoked')) {
                this.logHandlerService.addSystemLogCustom('License already revoked (ST00008)', 'Devices', 'Device list', 'SCP-Portal-ST00008');
                translatedErrorMessage = this.translate.instant('scp.devices.error_message.license_already_revoked');
              } else {
                translatedErrorMessage = err.error;
              }

              errorMessage = errorMessage + ' (' + this.dataSource.filteredData.find((x) => x.guid === guid).ip + ' - ' + translatedErrorMessage + ')';
              hasError = true;
              return EMPTY;
            })
          )
        ),
        finalize(() => {
          this.getDevicesData();
          this.selection.clear();
          this.isSubmitting = false;

          if (hasError) {
            isActivation
              ? this.logHandlerService.addSystemLogCustom('Device(s) did not activate (ST00009)', 'Devices', 'activate device', 'SCP-Portal-ST00009')
              : this.logHandlerService.addSystemLogCustom('Device(s) did not deactivate (ST000010)', 'Devices', 'deactivate device', 'SCP-Portal-ST00010');
            this.appService.showError(
              this.translate.instant(isActivation ? 'scp.devices.activation_error_message' : 'scp.devices.deactivation_error_message') + errorMessage
            );
          } else {
            this.appService.showSuccess(
              this.translate.instant(isActivation ? 'scp.devices.activation_success_message' : 'scp.devices.deactivation_success_message')
            );
          }
        })
      )
      .subscribe();
  }

  getDevicesData() {
    forkJoin([this.appService.getPrinters(), this.appService.getLicenseInfo()])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([printersData, licenseData]) => {
        this.dataSource.data = printersData;
        this.scpLicenseInfo = licenseData?.subscriptions?.scp;
      });
  }

  deleteDevice(data) {
    let hasError = false;
    let errorMessage = '';
    this.appService
      .deletePrinter(data.guid)
      .pipe(
        this.takeUntilDestroyed(),
        catchError((err) => {
          errorMessage = errorMessage + ' (' + this.dataSource.filteredData.find((x) => x.guid === data.guid).ip + ' - ' + err.error;
          +')';
          hasError = true;
          return EMPTY;
        }),
        finalize(() => {
          this.getDevicesData();
          if (hasError) {
            this.appService.showError(this.translate.instant('scp.devices.delete_error_message') + errorMessage);
          } else {
            this.appService.showSuccess(this.translate.instant('scp.devices.delete_success_message'));
          }
        })
      )
      .subscribe();
  }

  deleteDeviceDialog(data: { name: any; activated: string | boolean; guid: string }) {
    return new Promise((resolve, reject) => {
      let hasError = false;
      let errorMessage = '';

      const subDialogInfo: SubDialogInfo = {
        title: this.translate.instant('scp.common.confirm'),
        mainMessage: this.translate.instant('scp.devices.confirm_delete_message', { value: data.name }),
        cancelText: this.translate.instant('scp.common.cancel_button_text'),
        confirmText: this.translate.instant('scp.common.ok_button_text'),
        infoMode: false,
        placeHolderVariables: this.translate.instant('scp.devices.confirm_delete_message', { value: data.name }),
      };
      this.dialogService.open(subDialogInfo);
      this.dialogService.confirmed().subscribe((result) => {
        //Indicates Action Confirmed
        if (result === 1 && data.activated !== '' && data.activated !== null && data.guid !== '' && data.guid !== null) {
          //If Printer is Activated, Deactivate Printer and then Delete Printer
          if (data.activated === true) {
            this.appService
              .deActivatePrinter(data.guid)
              .pipe(
                this.takeUntilDestroyed(),
                catchError((err) => {
                  let translatedErrorMessage = '';
                  if ((err.error = 'SCP Error: license already revoked')) {
                    translatedErrorMessage = this.translate.instant('scp.devices.error_message.license_already_revoked');
                  } else {
                    translatedErrorMessage = err.error;
                  }

                  errorMessage = errorMessage + ' (' + this.dataSource.filteredData.find((x) => x.guid === data.guid).ip + ' - ' + translatedErrorMessage + ')';
                  hasError = true;
                  return EMPTY;
                }),
                finalize(() => {
                  this.getDevicesData();
                  if (hasError) {
                    this.appService.showError(this.translate.instant('scp.devices.delete_error_message') + errorMessage);
                  }
                })
              )
              .subscribe(() => {
                if (!hasError) {
                  this.deleteDevice(data);
                }
              });
          }
          //If Printer is Deactivated, Delete Printer
          else {
            if (!hasError) {
              this.deleteDevice(data);
            }
          }
        } else {
          //Indicates Action Cancelled
          resolve(0);
        }
      });
    });
  }
}
