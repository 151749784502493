import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../../../shared/components/base.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

const PAGE_NAME = 'JOBS_HISTORY';

@Component({
  selector: 'app-jobs-history',
  templateUrl: './jobs-history.component.html',
})
export class JobsHistoryComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['jobStartTime', 'displayName', 'fileName', 'modelName', 'jobType', 'status'];

  currentDate;
  startDateMinDate;
  startDateMaxDate;
  endDateMinDate;
  endDateMaxDate;
  startDate;
  endDate;

  executeFormGroup: FormGroup;
  fromDateFormCtrl: FormControl;
  toDateFormCtrl: FormControl;

  constructor() {
    super();

    this.currentDate = moment().toDate();
    this.endDate = moment().toDate();
    this.startDate = moment(this.endDate).add(-7, 'd').toDate();
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 0);

    this.startDateMaxDate = this.endDate;
    this.endDateMinDate = this.startDate;
    this.endDateMaxDate = this.currentDate;

    this.fromDateFormCtrl = new FormControl(this.startDate, [Validators.required]);
    this.toDateFormCtrl = new FormControl(this.endDate, [Validators.required]);

    this.executeFormGroup = new FormGroup({
      fromDttm: this.fromDateFormCtrl,
      toDttm: this.toDateFormCtrl,
    });
  }

  ngOnInit() {
    var fromDttm = this.fromDateFormCtrl.value;
    var toDttm = this.toDateFormCtrl.value;
    fromDttm.setHours(0, 0, 0, 0);
    toDttm.setHours(23, 59, 59, 0);

    this.getPrintJobsData(moment(fromDttm).utc().toISOString(), moment(toDttm).utc().toISOString());
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.defaultSortingDataAccessor;

    // Reset the page number
    //this.sort.sortChange.pipe(this.takeUntilDestroyed()).subscribe(() => (this.paginator.pageIndex = 0));
    this.sort.sortChange.emit();

    Promise.resolve().then(() => {
      this.paginator.pageSize = this.appService.getPageSizeInfo(PAGE_NAME);
    });

    this.paginator.page.pipe(this.takeUntilDestroyed()).subscribe((evt) => {
      this.appService.savePageSizeInfo(PAGE_NAME, evt.pageSize);
    });
  }

  applyFilter(filterString = '') {
    this.dataSource.filter = filterString.trim().toLowerCase();
  }

  startDateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    this.refreshJobs();
    this.endDateMinDate = this.fromDateFormCtrl.value;
  }

  endDateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    this.refreshJobs();
    this.startDateMaxDate = this.toDateFormCtrl.value;
  }

  refreshJobs() {
    var fromDttm = this.fromDateFormCtrl.value;
    var toDttm = this.toDateFormCtrl.value;
    fromDttm.setHours(0, 0, 0, 0);
    toDttm.setHours(23, 59, 59, 0);

    this.getPrintJobsData(moment(fromDttm).utc().toISOString(), moment(toDttm).utc().toISOString());
  }

  getPrintJobsData(startDate, endDate) {
    this.appService
      .getPrintHistoryJobs(startDate, endDate)
      .pipe(
        this.takeUntilDestroyed(),
        map((data) => {
          return (data || []).map((item) => {
            let formattedJobStartTime = '-';

            if (item.status.jobStartTime) {
              if (moment(item.status.jobStartTime + 'Z').isValid()) {
                formattedJobStartTime = moment(item.status.jobStartTime + 'Z')
                  .local()
                  .locale(navigator.language)
                  .format('L LTS');
              } else {
                formattedJobStartTime = moment(item.status.jobStartTime).local().locale(navigator.language).format('L LTS');
              }
            }

            return {
              formattedJobStartTime,
              jobStartTime: item.status.jobStartTime,
              displayName: item.userInfo.displayName,
              fileName: item.attributes.fileName,
              modelName: item.deviceInfo.modelName,
              jobType: item.jobType,
              status: item.status.status,
            };
          });
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
      });
  }
}
