import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iif } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { BaseComponent } from '../../../shared/components/base.component';
import { LogHandlerService } from '../../../shared/services/ssp/log-handler.service';

@Component({
  selector: 'app-device-upsert',
  templateUrl: './device-upsert.component.html',
  styleUrls: ['./device-upsert.component.scss'],
})
export class DeviceUpsertComponent extends BaseComponent {
  isAdd = false;
  isSubmitting = false;
  isChecked = false;
  deviceFormGroup: FormGroup;
  typeFormCtrl: FormControl;
  nameFormCtrl: FormControl;
  ipFormCtrl: FormControl;
  uuidFormCtrl: FormControl;
  machineIdFormCtrl: FormControl;
  macFormCtrl: FormControl;
  modelFormCtrl: FormControl;
  serialFormCtrl: FormControl;
  osFormCtrl: FormControl;
  osaAppIdFormCtrl: FormControl;
  locationFormCtrl: FormControl;
  directPrintFormCtrl: FormControl;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialogRef: MatDialogRef<DeviceUpsertComponent>, private logHandlerService: LogHandlerService) {
    super();

    this.isAdd = !data;

    this.typeFormCtrl = new FormControl(data?.type?.name, [Validators.required]);
    this.nameFormCtrl = new FormControl(data?.name, [Validators.required, Validators.pattern(this.validationRegExp.alphaNumericEx)]);
    this.ipFormCtrl = new FormControl(data?.ip, [Validators.required, Validators.pattern(this.validationRegExp.ipAddress)]);
    if (this.isAdd) {
      this.uuidFormCtrl = new FormControl(data?.uuid, [Validators.required, Validators.pattern(this.validationRegExp.alphaNumericEx)]);
    } else {
      this.uuidFormCtrl = new FormControl({ value: data?.uuid, disabled: true }, [
        Validators.required,
        Validators.pattern(this.validationRegExp.alphaNumericEx),
      ]);
    }
    this.machineIdFormCtrl = new FormControl(data?.machineId, [Validators.required, Validators.pattern(this.validationRegExp.alphaNumericEx)]);
    this.macFormCtrl = new FormControl(data?.mac, [Validators.required, Validators.pattern(this.validationRegExp.macAddress)]);
    this.modelFormCtrl = new FormControl(data?.model, [Validators.required, Validators.pattern(this.validationRegExp.alphaNumericEx)]);
    this.serialFormCtrl = new FormControl(data?.serial, [Validators.required, Validators.pattern(this.validationRegExp.alphaNumericEx)]);
    this.osFormCtrl = new FormControl(data?.osVersion, [Validators.required, Validators.pattern(this.validationRegExp.alphaNumericEx)]);
    this.osaAppIdFormCtrl = new FormControl(data?.osaAppId, [Validators.required, Validators.pattern(this.validationRegExp.all)]);
    this.locationFormCtrl = new FormControl(data?.location || '', [Validators.pattern(this.validationRegExp.alphaNumericEx)]);
    this.directPrintFormCtrl = new FormControl(data?.directPrintEnabled || false);

    this.deviceFormGroup = new FormGroup({
      type: this.typeFormCtrl,
      name: this.nameFormCtrl,
      ip: this.ipFormCtrl,
      uuid: this.uuidFormCtrl,
      machineId: this.machineIdFormCtrl,
      mac: this.macFormCtrl,
      model: this.modelFormCtrl,
      serial: this.serialFormCtrl,
      os: this.osFormCtrl,
      osaAppId: this.osaAppIdFormCtrl,
      location: this.locationFormCtrl,
      directPrintEnabled: this.directPrintFormCtrl,
    });
  }

  updateDirectPrintEnabled() {
    // do nothing
  }

  upsertDevice() {
    if (this.deviceFormGroup.valid) {
      this.uuidFormCtrl.enable();
      const model = this.deviceFormGroup.value;

      this.isSubmitting = true;

      iif(
        () => this.isAdd, // prettier-ignore
        this.appService.createPrinter(model, model.type),
        this.appService.updatePrinter(model)
      )
        .pipe(this.takeUntilDestroyed())
        .subscribe(
          () => {
            this.dialogRef.close(true);
          },
          (err: HttpErrorResponse) => {
            this.isSubmitting = false;

            this.logHandlerService.addSystemLogCustom('Device data failed to save (ST00012)', 'Devices', 'save device', 'SCP-Portal-ST00012');
            let errMsg = 'scp.devices.upsert.save_error_message';

            // 409: Conflict error - https://httpstatuses.io/409
            if (err.status === 409) {
              this.logHandlerService.addSystemLogCustom(
                'Device UUID already exists (ST00013)',
                'Devices',
                'save device (UUID already exists)',
                'SCP-Portal-ST00013'
              );
              errMsg = 'scp.devices.upsert.' + err.error.toLowerCase();
            }

            this.appService.showError(this.translate.instant(errMsg));
          }
        );

      this.uuidFormCtrl.disable();
    }
  }
}
