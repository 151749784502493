<mat-card>
  <mat-card-actions class="mb-0 fx_space-between-end">
    <span class="fx_rowwarp fx_startcenter">
      <button
        class="large"
        mat-icon-button
        color="primary"
        matTooltip="{{ 'scp.reports.execute.add_report_button_text' | translate }}"
        (click)="showUpsertCustomReportDialog()"
      >
        <mat-icon>add_circle</mat-icon>
      </button>

      <mat-form-field class="mx-3">
        <mat-label>{{ 'scp.reports.execute.filter_execute_reports_search_text' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" />
      </mat-form-field>
    </span>

    <button mat-icon-button matTooltip="{{ 'scp.common.refresh_data_button_text' | translate }}" (click)="getReportsData()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-card-content>
    <div class="table-responsive mat-elevation-z8">
      <table mat-table matSort matSortActive="name" matSortDirection="asc" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.execute.columns.report_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{
              hasTranslation('scp.report.' + row.name.replaceAll(' ', '').toLowerCase())
                ? ('scp.report.' + row.name.replaceAll(' ', '').toLowerCase() | translate)
                : row.name
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.reports.execute.columns.report_type' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>{{ 'scp.reports.execute.columns.actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [matMenuTriggerFor]="rowMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #rowMenu="matMenu">
              <button mat-menu-item (click)="showExecuteReportDialog(row)">{{ 'scp.common.execute_button_text' | translate }}</button>
              <button mat-menu-item disabled hidden>{{ 'scp.common.schedule_button_text' | translate }}</button>

              <ng-container *ngIf="!row.isPredefined">
                <button mat-menu-item (click)="showUpsertCustomReportDialog(row)">{{ 'scp.common.edit_button_text' | translate }}</button>
                <button mat-menu-item (click)="deleteReport(row)">{{ 'scp.common.delete_button_text' | translate }}</button>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
