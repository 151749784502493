<mat-card>
  <mat-card-actions class="mb-0 fx_space-between-end">
    <div class="fx_start fx_gap_10">
      <mat-form-field>
        <mat-label> {{ 'scp.jobs.history.filter_history_jobs_search_text' | translate }} </mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)" />
      </mat-form-field>

      <mat-form-field class="margin_5">
        <mat-label>{{ 'scp.jobs.history.filter_start_date' | translate }}</mat-label>
        <input
          matInput
          id="startDate"
          #startDate
          (click)="picker.open()"
          (dateChange)="startDateChangeEvent($event)"
          readonly
          [matDatepicker]="picker"
          [min]="startDateMinDate"
          [max]="startDateMaxDate"
          [formControl]="fromDateFormCtrl"
        />
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="margin_5">
        <mat-label>{{ 'scp.jobs.history.filter_end_date' | translate }}</mat-label>
        <input
          matInput
          id="endDate"
          #endDate
          (click)="picker1.open()"
          (dateChange)="endDateChangeEvent($event)"
          readonly
          [matDatepicker]="picker1"
          [min]="endDateMinDate"
          [max]="endDateMaxDate"
          [formControl]="toDateFormCtrl"
        />
        <mat-datepicker-toggle matSuffix [for]="picker1">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>

    <button mat-icon-button matTooltip="{{ 'scp.common.refresh_data_button_text' | translate }}" (click)="refreshJobs()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-actions>

  <mat-card-content>
    <div class="table-responsive mat-elevation-z8">
      <table mat-table matSort matSortActive="jobStartTime" matSortDirection="desc" [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="jobStartTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.history.columns.start_at' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.formattedJobStartTime }}
          </td>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.history.columns.user_display_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.displayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.history.columns.file_name' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.fileName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modelName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.history.columns.device_model' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.modelName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="jobType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.history.columns.job_type' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ 'scp.jobs.history.job_type.' + (row.jobType | lowercase) | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'scp.jobs.history.columns.job_status' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ 'scp.jobs.history.job_status.' + (row.status | lowercase) | translate }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
