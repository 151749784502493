import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BaseComponent } from '../../shared/components/base.component';
import { DepartmentUpsertComponent } from './department-upsert/department-upsert.component';

const PAGE_NAME = 'DEPARTMENTS';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
})
export class DepartmentsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['select', 'name', 'actions'];

  constructor() {
    super();
  }

  ngOnInit() {
    this.getDepartmentsData();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.defaultSortingDataAccessor;

    // Reset the page number
    //this.sort.sortChange.pipe(this.takeUntilDestroyed()).subscribe(() => (this.paginator.pageIndex = 0));
    this.sort.sortChange.emit();

    Promise.resolve().then(() => {
      this.paginator.pageSize = this.appService.getPageSizeInfo(PAGE_NAME);
    });

    this.paginator.page.pipe(this.takeUntilDestroyed()).subscribe((evt) => {
      this.appService.savePageSizeInfo(PAGE_NAME, evt.pageSize);
    });
  }

  applyFilter(filterString = '') {
    this.dataSource.filter = filterString.trim().toLowerCase();
  }

  isAllRowsSelected() {
    return this.selection.selected.length === this.dataSource.filteredData.length;
  }

  toggleAllRows() {
    if (this.isAllRowsSelected()) {
      return this.selection.clear();
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  showUpsertDepartmentDialog(rowData?) {
    this.dialog
      .open(DepartmentUpsertComponent, {
        data: rowData,
        width: '500px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed<boolean>())
      .subscribe((shouldUpdateView) => {
        if (shouldUpdateView) {
          this.getDepartmentsData();
        }
      });
  }

  getDepartmentsData() {
    this.appService
      .getDepartments()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.dataSource.data = data;
      });
  }
}
